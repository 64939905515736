import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as userActions from '../../store/user/actions';
import * as userSelectors from '../../store/user/reducer';
import PureLogin from './PureLogin';
import './style.scss';
// import * as firebase from 'firebase';

const Login = ({ token, login }) => {
  return (
    <>
      {token && token.length > 0 ? (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      ) : (
        <PureLogin login={login} />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  const login = () => dispatch(userActions.googleSignIn());
  const logout = () => dispatch(userActions.logout());

  return { login, logout };
};

const mapStateToProps = (state) => {
  const token = userSelectors.getUserToken(state);
  const loginError = userSelectors.getLoginError(state);
  return { token, loginError };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
