/**
 *
 * @param {* File} file A file that will be compared by type with each of the fileTypes array`s items
 * @param {* Array<string>} fileTypes Array of file types to compare with the file.type
 * @returns A boolean which indicates the validity of the file.type by comparison to each item of the fileTypes array
 */
function validFileType(file, fileTypes) {
  return fileTypes.includes(file.type);
}

export default validFileType;
