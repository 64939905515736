import { connect } from 'react-redux';
import * as userActions from '../../store/user/actions';
import * as activitesActions from '../../store/activites/actions';
import * as activitesSelectors from '../../store/activites/reducer';
import PureHome from './PureHome';
// import Home from './Home';

const mapDispatchToProps = (dispatch) => {
  const googleLogout = () => dispatch(userActions.googleLogout());
  const fetchAllActivities = () =>
    dispatch(activitesActions.fetchAllActivities());
  const fetchSettings = () => dispatch(activitesActions.getSettings());
  const resetNewActivity = () => dispatch(activitesActions.resetNewActivity());
  return { googleLogout, fetchAllActivities, fetchSettings, resetNewActivity };
};

const mapStateToProps = (state) => {
  const allActivities = activitesSelectors.getAllActivities(state);
  const selectedList = activitesSelectors.getSelectedList(state);
  return { allActivities, selectedList };
};

export default connect(mapStateToProps, mapDispatchToProps)(PureHome);
