import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tab: {
    paddingTop: '15px',
    borderBottom: '1px solid #d2d2d2',
  },
  paperTitle: {
    padding: '25px',
    fontSize: '16px',
  },
  dropZone: {
    padding: '60px',
    textAlign: 'center',
    fontSize: '16px',
    outline: 'none',
    cursor: 'pointer',
    '& span': {
      color: '#D10076',
    },
  },
  subTitle: {
    fontSize: '14px',
    color: '#66788A',
    paddingLeft: '10px',
  },
  img: {
    height: '200px',
    margin: '20px',
    width: '70%',
  },
  cancel: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  typeIndicator: {
    color: '#66788A',
  },
}));

export default useStyles;
