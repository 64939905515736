import * as types from './actionTypes';
import apiService from '../../services/rtApi';
import { fireAuth, authProvider } from '../../fireApi';
import * as firebase from 'firebase';
import history from '../../history';

/**
 * * TODO: Remove routing from actions
 * ? TODO: Refactor to userActionCreator object and actions.
 */

export function logout() {
  return async (dispatch) => {
    dispatch({
      type: types.USER_LOGOUT,
    });
  };
}

export function googleSignIn() {
  return async (dispatch) => {
    try {
      const result = await fireAuth.signInWithPopup(authProvider);
      const token = await fireAuth.currentUser.getIdToken();

      localStorage.setItem('user', JSON.stringify(result.user));
      localStorage.setItem('token', token);
      apiService
        .checkPrivileges()
        .then(({ isAdmin }) => {
          if (isAdmin) {
            dispatch({
              type: types.USER_LOGIN_SUCCESSFUL,
              token,
            });
            history.push('/');
          } else {
            dispatch({
              type: types.USER_LOGIN_ERROR,
              error: { message: 'No permission' },
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: types.USER_LOGIN_ERROR,
            error,
          });
        });
    } catch (err) {
      let error = err;
      console.log(error);
      try {
        error = err.response.data;
      } catch (e) {
        dispatch({
          type: types.USER_LOGIN_ERROR,
          error,
        });
      }
    }
  };
}

export function refreshToken() {
  return async (dispatch) => {
    try {
      const result = fireAuth.currentUser;
      const token = await fireAuth.currentUser.getIdToken();
      localStorage.setItem('user', result.user);
      localStorage.setItem('token', token);
      apiService
        .checkPrivileges()
        .then(({ isAdmin }) => {
          if (isAdmin) {
            dispatch({
              type: types.USER_LOGIN_SUCCESSFUL,
              token,
            });
          } else {
            dispatch({
              type: types.USER_LOGIN_ERROR,
              error: { message: 'No permission' },
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: types.USER_LOGIN_ERROR,
            error,
          });
        });
    } catch (err) {
      let error = err;
      console.log(error);
      try {
        error = err.response.data;
      } catch (e) {}
      dispatch({
        type: types.USER_LOGIN_ERROR,
        error,
      });
    }
  };
}

export function googleLogout() {
  return async (dispatch) => {
    try {
      await firebase.auth().signOut();
      // localStorage.removeItem('user');
      localStorage.clear();
      window.location.replace('/login');
      dispatch({
        type: types.USER_LOGOUT,
      });
    } catch (err) {
      let error = err;
      try {
        error = err.response.data;
      } catch (e) {}
      dispatch({
        type: types.USER_LOGIN_ERROR,
        error,
      });
    }
  };
}
