import * as types from './actionTypes';
import * as firebase from 'firebase';
const jwt = require('jsonwebtoken');

const initialState = {
  token: null,
  loginError: '',
};

export default function reduce(state, action = {}) {
  if (!state) state = initialState;

  switch (action.type) {
    case types.USER_LOGIN_SUCCESSFUL:
      const { token } = action;

      return { ...state, token };
    case types.USER_LOGIN_ERROR:
      const { error: loginError } = action;
      return { ...state, loginError };
    case types.USER_LOGIN_CLEAR:
      return {
        ...state,
        loginError: '',
      };
    case types.USER_LOGOUT:
      return { ...state, token: null, loginError: '' };
    default:
      return state;
  }
}

// selectors

export function getUserToken(state) {
  return state.user.token;
}

export function getUserName(state) {
  const token = state.user.token;
  const data = jwt.decode(token);
  return data ? data.userName : '';
}

export function getLoginError(state) {
  return state.user.loginError;
}

export function getIsLoggedIn(state) {
  const { token } = state.user;
  return token && token.length > 0;
}
