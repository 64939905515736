export class ActivityProperties {
  cover_box;
  cover_rect;
  cover_small;
  description;
  display_name;
  media;
  tags;
  type;

  constructor(
    cover_box = '',
    cover_rect = '',
    cover_small = '',
    description = '',
    display_name = '',
    media = [],
    tags = [],
    type = '',
  ) {
    this.cover_box = cover_box;
    this.cover_rect = cover_rect;
    this.cover_small = cover_small;
    this.description = description;
    this.display_name = display_name;
    this.media = media;
    this.tags = tags;
    this.type = type;
  }
}
