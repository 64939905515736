import React, { useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Editor } from 'react-draft-wysiwyg';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import draftToHtml from 'draftjs-to-html';
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from 'draft-js';

const ActivityDetails = ({
  activity,
  editActivity,
  editorStateChange,
  disable,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(1);
  const [editorState, setEditorState] = React.useState(getEditorState(''));

  useEffect(() => {
    setEditorState(getEditorState(activity.properties.description));
  }, [activity]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleChange = (value) => {
    const data = { ...activity };
    data.properties.display_name = value.target.value;
    editActivity(data);
  };

  const onEditorStateChange = (res) => {
    setEditorState(res);
    const description = draftToHtml(convertToRaw(res.getCurrentContent()));
    const data = { ...activity };
    data.properties.description = description;
    editActivity(data);
  };

  return (
    <Accordion className="activity-card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <span className="activity-card-title">* Activity Details</span>
        <span className="activity-card-sub-title">
          Set name, description and other core settings
        </span>
      </AccordionSummary>
      <AccordionDetails className="activity-card">
        <Grid className="inputs-grid" container spacing={3}>
          <Grid item xs={6}>
            <TextField
              className="text-input"
              id="standard-basic"
              disabled
              value={activity?.properties?.id}
              label="Activity id *"
            />
            <TextField
              style={{ marginTop: '9px' }}
              className="text-input"
              id="standard-basic"
              onChange={handleChange}
              value={activity?.properties?.display_name}
              label="Activity name *"
              disabled={disable}
            />
          </Grid>
          <Grid className="html-editor" item xs={6}>
            <div className="description-label">Description *</div>
            <Editor
              toolbarHidden
              editorState={getEditorState(activity?.properties.description)}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editor-disabled"
              disabled={disable}
            />
            <Fab
              className="editor-button"
              color="secondary"
              aria-label="edit"
              onClick={handleClickOpen}
              disabled={disable}
            >
              <EditIcon />
            </Fab>
          </Grid>
        </Grid>
        <HtmlDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ActivityDetails;

const HtmlDialog = ({
  onClose,
  selectedValue,
  open,
  editorState,
  onEditorStateChange,
}) => {
  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Editor
        defaultEditorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
      />
    </Dialog>
  );
};

function getEditorState(value) {
  return EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(value)),
  );
}
