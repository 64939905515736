
export class ActivityFixedSettings {
  activityLengthType;
  assistant;
  colorsPerPlayer;
  competitionMode;
  cornerPodslightsOut;
  cycles;
  delayCycles;
  distractingColorsCount;
  distractingPodColor;
  distractingPods;
  duration;
  hitCount;
  homeBasePodColor;
  homeBasePodLightsOut;
  lightDelayFixed;
  lightDelayFixedCorners;
  lightDelayFixedHomeBase;
  lightDelayRandomFromCorners;
  lightDelayRandomFromHomeBase;
  lightDelayRandomToCorners;
  lightDelayRandomToHomeBase;
  lightDelayTimeFrom;
  lightDelayTimeTo;
  lightDelayTimeType;
  lightDelayTimeTypeCornerPods;
  lightDelayTimeTypeHomeBasePod;
  lightsOut;
  lightsOutType;
  lightsOutTypeCornerPods;
  lightsOutTypeHomeBasePod;
  players;
  pods;
  podsPerPlayer;
  repetitions;
  stations;
  steps;
  strikeOut;
  strikeOutType;
  strikes;
  whistleBeforeCorners;
  constructor(
    activityLengthType = 'time',
    assistant = 0,
    colorsPerPlayer = 1,
    competitionMode = 'regular',
    cornerPodslightsOut = 1,
    cycles = null,
    delayCycles = null,
    distractingColorsCount = 1,
    distractingPodColor = null,
    distractingPods = 1,
    duration = 30000,
    hitCount = 10,
    homeBasePodColor = null,
    homeBasePodLightsOut = 1,
    lightDelayFixed = 2,
    lightDelayFixedCorners = 2,
    lightDelayFixedHomeBase = 2,
    lightDelayRandomFromCorners = 1,
    lightDelayRandomFromHomeBase = 1,
    lightDelayRandomToCorners = 4,
    lightDelayRandomToHomeBase = 4,
    lightDelayTimeFrom = 1,
    lightDelayTimeTo = 4,
    lightDelayTimeType = 'none',
    lightDelayTimeTypeCornerPods = 'none',
    lightDelayTimeTypeHomeBasePod = null,
    lightsOut = 2,
    lightsOutType = 'hit',
    lightsOutTypeCornerPods = 'hit',
    lightsOutTypeHomeBasePod = 'hit',
    players = 1,
    pods = 3,
    podsPerPlayer = 24,
    repetitions = 10,
    stations = null,
    steps = 3,
    strikeOut = 5,
    strikeOutType = 'no',
    strikes = null,
    whistleBeforeCorners = 'no'
  ) {
    this.activityLengthType = activityLengthType;
    this.assistant = assistant;
    this.colorsPerPlayer = colorsPerPlayer;
    this.competitionMode = competitionMode;
    this.cornerPodslightsOut = cornerPodslightsOut;
    this.cycles = cycles;
    this.delayCycles = delayCycles;
    this.distractingColorsCount = distractingColorsCount;
    this.distractingPodColor = distractingPodColor;
    this.distractingPods = distractingPods;
    this.duration = duration;
    this.hitCount = hitCount;
    this.homeBasePodColor = homeBasePodColor;
    this.homeBasePodLightsOut = homeBasePodLightsOut;
    this.lightDelayFixed = lightDelayFixed;
    this.lightDelayFixedCorners = lightDelayFixedCorners;
    this.lightDelayFixedHomeBase = lightDelayFixedHomeBase;
    this.lightDelayRandomFromCorners = lightDelayRandomFromCorners;
    this.lightDelayRandomFromHomeBase = lightDelayRandomFromHomeBase;
    this.lightDelayRandomToCorners = lightDelayRandomToCorners;
    this.lightDelayRandomToHomeBase = lightDelayRandomToHomeBase;
    this.lightDelayTimeFrom = lightDelayTimeFrom;
    this.lightDelayTimeTo = lightDelayTimeTo;
    this.lightDelayTimeType = lightDelayTimeType;
    this.lightDelayTimeTypeCornerPods = lightDelayTimeTypeCornerPods;
    this.lightDelayTimeTypeHomeBasePod = lightDelayTimeTypeHomeBasePod;
    this.lightsOut = lightsOut;
    this.lightsOutType = lightsOutType;
    this.lightsOutTypeCornerPods = lightsOutTypeCornerPods;
    this.lightsOutTypeHomeBasePod = lightsOutTypeHomeBasePod;
    this.players = players;
    this.pods = pods;
    this.podsPerPlayer = podsPerPlayer;
    this.repetitions = repetitions;
    this.stations = stations;
    this.steps = steps;
    this.strikeOut = strikeOut;
    this.strikeOutType = strikeOutType;
    this.strikes = strikes;
    this.whistleBeforeCorners = whistleBeforeCorners;
  }
}
