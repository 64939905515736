import React, { useCallback, useEffect, useRef, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import {} from '@material-ui/core'
// import {} from '@material-ui/core'
import { MEDIA_TYPES } from '../../../utils/consts';
// import Upload from '../../components/Upload';
import DropMedia from '../../../components/DropMedia/DropMedia.component';
import { v4 as uuid } from 'uuid';
import FileUtil from '../../../utils/FileUtil';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { spacesToUnderScore } from './utils';

const ActivityMedia = ({ media = [], addMedia, deleteMedia }) => {
  const [selectedType, setSelectedType] = useState('');
  const [localMedia, setLocalMedia] = useState([]);

  const [selectedMedia, setSelectedMedia] = useState(null);

  const deleteRef = useRef();

  const initLocalMedia = useCallback(
    function () {
      const processedMedia = media.map((item) => ({
        ...item,
        local_id: item.local_id ? item.local_id : uuid(),
      }));
      setLocalMedia(processedMedia);
    },
    [media],
  );

  useEffect(() => {
    initLocalMedia();
  }, [initLocalMedia]);

  const handleTypeChange = (value) => {
    setSelectedType(value.target.value);
  };

  function addMediaItem(
    index,
    newItem = { type: selectedType, url: '', local_id: uuid(), fileName: '' },
  ) {
    setSelectedType('');
    if (!index) {
      setLocalMedia([...localMedia, { ...newItem }]);
    } else {
      const dupLocalMedia = [...media];
      dupLocalMedia.splice(index, 0, newItem);
      // console.log(dupLocalMedia);
      setLocalMedia(dupLocalMedia);
    }
  }

  /**
   * If the relevant item doesn't exist on the parent media array, remove it only from the local state
   * else delete it from the parent, and the local state will self-update.
   * @param {string} url (or base64 string)
   * @returns void
   */
  const deleteMediaItem = (file) => {
    const { url, local_id } = file;
    const mediaItem = media.find((item) => item.url === url);
    setSelectedMedia(null);
    if (!mediaItem) {
      setLocalMedia(localMedia.filter((item) => item.local_id !== local_id));
      return;
    } else deleteMedia(mediaItem);
    return;
  };

  const onDrop = (file, local_id) => {
    FileUtil.getBase64FromFile(file).then((getBase64FromFile) => {
      let mediaItem = localMedia.find((item) => item.local_id === local_id);
      mediaItem.url = getBase64FromFile;
      mediaItem.fileName = spacesToUnderScore(file.path);
      addMedia(mediaItem);
    });
    return;
  };
  const handleOpenDialog = (file) => {
    setSelectedMedia(file);
    deleteRef.current.openDialog();
  };

  return (
    <Accordion className="activity-card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <span className="activity-card-title">Activity Media</span>
        <span className="activity-card-sub-title">
          Define images and videos
        </span>
      </AccordionSummary>
      <AccordionDetails className="activity-card">
        {localMedia.length > 0 &&
          localMedia.map((item, index) => {
            const fileTypes =
              item.type === 'video' ? ['video/mp4'] : ['image/png'];
            return (
              <div key={`_${item.local_id}-${index}`} className="media-item">
                <Fab
                  className="delete-media-button"
                  color="secondary"
                  aria-label="add"
                  onClick={() => handleOpenDialog(item)}
                >
                  <DeleteForeverIcon />
                </Fab>
                <DropMedia
                  title={item.type}
                  filePath={item.url}
                  local_id={item.local_id}
                  fileTypes={fileTypes}
                  onDrop={(file) => onDrop(file, item.local_id)}
                  shouldRenderDel={false}
                />
              </div>
            );
          })}
        <Paper className="add-media">
          <div className="add-media-title">
            <Select
              labelId="demo-simple-select-label"
              className="select-media-type"
              id="demo-simple-select"
              value={selectedType}
              onChange={handleTypeChange}
              defaultValue=""
            >
              {MEDIA_TYPES.map(({ value, title }) => (
                <MenuItem key={value} value={value}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Fab
            onClick={() => addMediaItem()}
            disabled={!selectedType}
            className="add-media-button"
            color="primary"
            aria-label="add"
          >
            <AddIcon />
          </Fab>
        </Paper>
      </AccordionDetails>
      <ConfirmDialog
        ref={deleteRef}
        title={`Are you sure you want to delete this file?`}
        confirmed={() => deleteMediaItem(selectedMedia)}
      />
    </Accordion>
  );
};

export default ActivityMedia;

/* 

 const mediaItem = media.find((item) => item.local_id === local_id);
    if (!mediaItem) {
      const filteredMedia = localMedia.filter(
        (item) => item.local_id !== local_id,
      );
      setLocalMedia(filteredMedia);
    } else {
      clearLocalItem(local_id);

      initLocalMedia([...media, { ...mediaItem, url: '' }]);
    }*/
