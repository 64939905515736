import React from 'react';
import * as userSelectors from '../store/user/reducer';
import { store } from '../index';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const state = store && store.getState();
  const isLoggedIn = state ? userSelectors.getIsLoggedIn(state) : false;

  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedIn === true ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

function mapStateToProps(state) {
  const isLoggedIn = userSelectors.getIsLoggedIn(state);
  return {
    isLoggedIn,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
