import React, { useState } from 'react';
// import { connect } from 'react-redux';

import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import useStyles from './useStyles';
import validFileType from './validFileType';

function DropMedia({
  title,
  subTitle,
  filePath,
  fileTypes,
  customValidator,
  onDrop,
  onClickDelete,
  local_id,
  shouldRenderDel = true,
  disabled
}) {
  const classes = useStyles();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleOnDrop, disabled
  });

  const [currentFileName, setCurrentFileName] = useState();

  function validator(file) {
    const isTypeValid = validFileType(file, fileTypes);
    if (customValidator !== undefined) {
      return customValidator(file);
    }
    if (!isTypeValid)
      return {
        code: 'file-type-error',
        message: `This field only accepts files of type: ${fileTypes.join(
          ', ',
        )}`,
      };
    else return null;
  }

  function handleOnDrop(files) {
    if (currentFileName) return;

    const file = files[0];

    const validError = validator(file);

    if (validError !== null && validError.message) {
      toast.error(validError.message);
    } else {
      setCurrentFileName(file.path);
      onDrop(file, local_id);
    }
  }

  const renderSupported = () => {
    return fileTypes
      .map((type) => {
        return type.split('/')[1];
      })
      .join(', ');
  };

  const handleClickDelete = () => {
    onClickDelete();
    setCurrentFileName(undefined);
  };

  return (
    <Paper elevation={1}>
      <div className={classes.paperTitle}>
        <span>{title}</span>
        <span className={classes.subTitle}>{subTitle}</span>
      </div>
      <Divider />
      {filePath && filePath.length > 0 ? (
        <div style={{ textAlign: 'center', position: 'relative' }}>
          {shouldRenderDel && (
            <IconButton
              onClick={handleClickDelete}
              className={classes.cancel}
              aria-label="delete"
            >
              <CloseIcon></CloseIcon>
            </IconButton>
          )}
          {title === 'video' ? (
            <video
              className={classes.img}
              src={filePath}
              controls
              autoPlay
              muted
            ></video>
          ) : (
            <img alt={'File Preview'} className={classes.img} src={filePath} />
          )}
        </div>
      ) : (
        <div>
          <div className={classes.dropZone} {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>
                <ImageOutlinedIcon style={{ fontSize: 60 }} />
              </p>
            ) : (
              <>
                <p>
                  Drag your file here or <span>select from your device</span>
                </p>
                <em className={classes.typeIndicator}>
                  (Only files of type "{renderSupported()}" are supported.)
                </em>
              </>
            )}
          </div>
          <Divider />
        </div>
      )}
    </Paper>
  );
}
export default DropMedia;

// const [isUploading, setIsUploading] = useState(false);
// const [error, setError] = useState(null);
// const [progress, setProgress] = useState(0);
// const [successUrl, setSuccessUrl] = useState(null);

/* useEffect(() => {
    if (currentFileName) {
      setProgress(
        uploadProgress ? Math.round(uploadProgress(currentFileName)) : 0,
      );
      setError(uploadError ? uploadError(currentFileName) : null);
      setSuccessUrl(uploadSuccess ? uploadSuccess(currentFileName) : null);
    }

    if (successUrl && successUrl !== imgUrl) {
      clearFileResult(currentFileName);
      getFileUrl(successUrl);
      setImgUrl(successUrl);
    }

    if (error) console.log(error);
  }, [
    clearFileResult,
    currentFileName,
    error,
    getFileUrl,
    imgUrl,
    successUrl,
    uploadError,
    uploadProgress,
    uploadSuccess,
  ]); */

/* const mapDispatchToProps = (dispatch) => {
  const uploadFile = (file, toastId, folderId) =>
    dispatch(mediaActions.uploadFile(file, toastId, folderId));
  const cancelFileUpload = (fileName) =>
    dispatch(mediaActions.cancelFileUpload(fileName));
  const clearFileResult = (fileName) =>
    dispatch(mediaActions.clearFileResult(fileName));

  return { uploadFile, cancelFileUpload, clearFileResult };
}; */

/* const mapStateToProps = (state) => {
  const uploadProgress = (fileName) =>
    mediaSelectors.getUploadProgress(state, fileName);
  const uploadError = (fileName) =>
    mediaSelectors.getUploadError(state, fileName);
  const uploadSuccess = (fileName) =>
    mediaSelectors.getUploadSuccess(state, fileName);
  return { uploadProgress, uploadError, uploadSuccess };
}; */

// export default connect(mapStateToProps, mapDispatchToProps)(MediaDropZone);
