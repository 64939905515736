import * as types from './actionTypes';
import { CONSTS } from './consts';
import _, { cloneDeep } from 'lodash';

// import { getCoverKeys } from './utils';

const initialState = {
  activities: null,
  activity: null,
  settings: null,
  editActivity: null,
  filters: null,
  newActivity: cloneDeep(CONSTS.ACTIVITY_OBJ),
  selectedActivities: {},
  isAllSelected: false,
  showOriginalActivity: false,
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_ALL_ACTIVITIES_SUCCESSFUL:
      const { activities } = action.payload;
      return { ...state, activities };
    case types.GET_ACTIVITY_SUCCESSFUL:
      const activity = action.payload;
      return { ...state, activity, editActivity: activity };
    case types.GET_ACTIVITY_SETTINGS_SUCCESSFUL:
      const settings = { ...action.payload };
      return { ...state, settings };
    case types.EDIT_ACTIVITY_SUCCESSFUL:
      return { ...state, activity: { ...action.payload } };
    case types.GET_ACTIVITY_FILTERS_SUCCESSFUL:
      const filters = action.payload;
      return { ...state, filters };
    case types.RESET_ACTIVITY_SUCCESSFUL:
      return { ...state, editActivity: state.activity };
    case types.NEW_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        newActivity: cloneDeep(CONSTS.ACTIVITY_OBJ),
        activity,
      };
    case types.UPDATE_ACTIVITY_SUCCESSFUL:
      return {
        ...state,
        activity: action.payload,
        editActivity: action.payload,
      };

    case types.SELECT_ACTIVITY:
      const { selectedActivities = {} } = state;
      const id = action.payload;
      const { [id]: exist, ...list } = selectedActivities;
      return {
        ...state,
        selectedActivities: { ...list, ...(!exist ? { [id]: id } : null) },
      };

    case types.SELECT_ALL_ACTIVITIES:
      return {
        ...state,
        isAllSelected: !!action.payload.length,
        selectedActivities: action.payload.reduce(
          (obj, item) => ({
            ...obj,
            [item]: item,
          }),
          {},
        ),
      };
    case types.NEW_ACTIVITY_CHANGED:
      return { ...state, newActivity: { ...action.payload } };

    case types.NEW_ACTIVITY_CREATED_SUCCESSFUL:
      return {
        ...state,
        // activity: cloneDeep(CONSTS.ACTIVITY_OBJ),
        activity: cloneDeep(action.payload),
        editActivity: cloneDeep(CONSTS.ACTIVITY_OBJ),
        newActivity: cloneDeep(action.payload),
      };
    case types.NEW_ACTIVITY_RESET:
      return { ...state, newActivity: cloneDeep(CONSTS.ACTIVITY_OBJ) };

    case types.SET_ACTIVITY_TO_EDIT:
      return { ...state, activity: action.payload };

    case types.SET_SHOW_ORIGINAL_ACTIVITY:
      return { ...state, showOriginalActivity: action.payload };
    case types.SET_COVER:
      return {
        ...state,
        activity: {
          ...state.activity,
          properties: { ...state.activity.properties, ...action.payload },
        },
      };
    case types.SET_COVER_NEW:
      const activityObject = {
        ...state.activity,
        properties: { ...state.activity.properties, ...action.payload },
      };
      return {
        ...state,
        activity: activityObject,
        newActivity: activityObject,
      };
    default:
      return state;
  }
}

// selectors

export function getAllActivities(state) {
  return state.activities.activities;
}

export function getActivity({ activity }) {
  return activity;
}

export function getSettings(state) {
  return state.activities.settings;
}

export function getEditActivity(state) {
  return state.activities.editActivity;
}

export function getFilters(state) {
  return state.activities.filters;
}

export function getNewActivity(state) {
  return state.activities.newActivity;
}

export function getSelectedList(state) {
  return state.activities.selectedActivities;
}

export function getShowOriginal(state) {
  return state.activities.showOriginalActivity;
}

// activity media selectors
export function getActivityCovers(activity) {
  return _.pick(activity.properties, [
    'cover_small',
    'cover_rect',
    'cover_box',
  ]);
}
