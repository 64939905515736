import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ActivitiesList from './ActivitiesList';
import './style.scss';
import * as activitiesActions from '../../store/activites/actions';

const mapDispatchToProps = (dispatch) => {
  const createActivity = (obj) =>
    dispatch(activitiesActions.createActivity(obj));
  const updateActivity = (obj) =>
    dispatch(activitiesActions.updateActivity(obj));
  const deleteActivity = (id) => dispatch(activitiesActions.deleteActivity(id));
  const selectActivity = (id) => dispatch(activitiesActions.selectActivity(id));
  const publishActivity = (id) =>
    dispatch(activitiesActions.publishActivity(id));
  const unpublishActivity = (id) =>
    dispatch(activitiesActions.unpublishActivity(id));
  const selectAllActivities = (id) =>
    dispatch(activitiesActions.selectAllActivities(id));
  const deleteBulkActivities = (list) =>
    dispatch(activitiesActions.deleteBulkActivities(list));
  const visibleBulkActivities = (selected, list, states) =>
    dispatch(activitiesActions.visibleBulkActivities(selected, list, states));
  const markBulkAsNewSelected = (selected, list, date) =>
    dispatch(activitiesActions.markBulkAsNewSelected(selected, list, date));
  const setActivityToEdit = (activity) =>
    dispatch(activitiesActions.setActivityToEdit(activity));
  const editNewUntil = (obj) => dispatch(activitiesActions.editNewUntil(obj));
  const fetchAllActivities = () =>
    dispatch(activitiesActions.fetchAllActivities());
  return {
    createActivity,
    updateActivity,
    deleteActivity,
    selectActivity,
    selectAllActivities,
    deleteBulkActivities,
    visibleBulkActivities,
    markBulkAsNewSelected,
    setActivityToEdit,
    publishActivity,
    fetchAllActivities,
    unpublishActivity,
    editNewUntil,
  };
};

const mapStateToProps = (state) => {
  return state;
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActivitiesList),
);
