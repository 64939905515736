import { IconButton } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import React from 'react';

const FilterButton = (props) => {
  const { filterName, filterFunc, filterState } = props;
  return (
    <>
      <IconButton
        className="filter-activities-button"
        variant="contained"
        color={filterName === filterState ? 'primary' : 'default'}
        onClick={() => filterFunc(filterName)}
      >
        <span style={{ marginRight: '0.5em' }}>{props.children}</span>
        <FilterList />
      </IconButton>
    </>
  );
};

export default FilterButton;
