import React, { Fragment, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import { startCase } from 'lodash';
import Slider from '@material-ui/core/Slider';
import Select from '@material-ui/core/Select';
import { COLORS, EXCLUDE_SETTINGS } from '../../../utils/consts';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const ActivitySettings = ({
  activity,
  settings,
  editActivity,
  orderedSettings,
  disable,
}) => {
  const [numericKeys, setNumericKey] = useState('');
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [selectedParticipantType, setSelectedParticipantType] = useState(activity?.settings?.participantType ?? 'PLAYER');

  function calculateTime(_time = activity.settings.fixed.duration / 1000) {
    const seconds = _time % 60;
    const minutes = Math.floor(_time / 60);
    return { seconds, minutes };
  }

  const handleTimeChange = ({ seconds, minutes }) => {
    const { duration } = activity.settings.fixed;
    let _time = duration / 1000;
    if (!!seconds || seconds === 0) {
      const _prevMins = Math.floor(_time / 60);
      _time = _prevMins * 60 + seconds;
    }
    if (!!minutes || minutes === 0) {
      const _prevSecs = _time % 60;
      _time = minutes * 60 + _prevSecs;
    }
    updateActivityState({ type: 'duration', value: _time * 1000 });
  };

  const updateActivityState = ({ type, value }) => {
    const data = getActivityMergedFixed({
      activity,
      type,
      value,
    });
    editActivity(data);
  };

  const selectSetting = (obj) => {
    if (obj === 'sequenceSteps') {
      setSelectedSetting('sequenceSteps');
    } else if (obj === 'participantType') {
      setSelectedSetting('participantType');
    } else {
      const { dependents = [], type } = obj.itemSettings;
      const value = activity.settings.fixed[type];
      const numericKeys = getNumericKey({ dependents, value });
      setNumericKey(numericKeys);
      setSelectedSetting(obj);
    }
  };

  const handleChange = ({ target: { value } }) => {
    const { type, dependents = [] } = selectedSetting.itemSettings;
    const numericKey = getNumericKey({ dependents, value });
    setNumericKey(numericKey);
    updateActivityState({ type, value });
  };

  const handleChangeParticipantType = (event, index) => {
    activity.settings.participantType = index;
    setSelectedParticipantType(index)
    editActivity(activity);
  };

  const handleNumericKey = (event, value, type) => {
    updateActivityState({ type, value });
  };

  function sliderValue(select, start, isTimed) {
    return select ? select / (isTimed ? 1000 : 1) : start;
  }

  const handleSliderChange = ({ type, value }) => {
    updateActivityState({ type, value });
  };

  const selectedValue =
    activity?.settings?.fixed[selectedSetting?.itemSettings?.type] ||
    selectedSetting?.itemSettings?.start;

  const selectedEnumValue = () => {
    return (
      activity?.settings?.fixed[selectedSetting?.itemSettings?.type] ||
      settings?.settings?.enum[selectedSetting?.engineItem].values[
        settings?.settings?.enum[selectedSetting?.engineItem].initial
      ].key
    );
  };

  const handleChangePodName = (event, index) => {
    const data = { ...activity };
    const arr = data.settings.sequencePods || [];
    arr[index] = event.target.value;
    data.settings.sequencePods = arr;
    editActivity(data);
  };

  const handleChangePodColor = (event, index) => {
    const data = { ...activity };
    const arr = data.settings.colors || [];
    arr[index] = event.target.value.key;
    data.settings.colors = arr;
    editActivity(data);
  };

  const sequencePodsValue = (i) => {
    return activity.settings?.sequencePods
      ? activity.settings?.sequencePods[i]
      : '';
  };

  const colorsValue = (i) => {
    return activity.settings?.colors
      ? COLORS[activity.settings?.colors[i]]
      : '';
  };

  return (
    <Accordion className="activity-card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <span className="activity-card-title">Activity Settings</span>
        <span className="activity-card-sub-title">
          Configure behavioral settings for this activity
        </span>
      </AccordionSummary>
      <AccordionDetails className="activity-card">
        <Paper className="settings-paper">
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <MenuList
                component="nav"
                aria-label="main mailbox folders"
                className="settings-menu"
              >
                {orderedSettings
                  ?.filter(
                    (value) =>
                      !EXCLUDE_SETTINGS.includes(value.itemSettings.type),
                  )
                  .map((value, index) => {
                    if (value.itemSettings.type === 'competitionMode') {
                      if (activity.settings.fixed.players > 1) {
                        return (
                          <MenuItem
                            key={index}
                            className={
                              selectedSetting?.engineItem === value?.engineItem
                                ? 'selected'
                                : ''
                            }
                            onClick={() => selectSetting(value)}
                            button
                          >
                            {startCase(value.itemSettings.type)}
                          </MenuItem>
                        );
                      }
                      return null;
                    }
                    return (
                      <MenuItem
                        key={index}
                        className={
                          selectedSetting?.engineItem === value?.engineItem
                            ? 'selected'
                            : ''
                        }
                        onClick={() => selectSetting(value)}
                        button
                      >
                        {startCase(value.itemSettings.type)}
                      </MenuItem>
                    );
                  })}
                {activity?.engine === 'sequence' && (
                  <MenuItem
                    key={'sequenceSteps'}
                    className={
                      selectedSetting === 'sequenceSteps' ? 'selected' : ''
                    }
                    onClick={() => selectSetting('sequenceSteps')}
                    button
                  >
                    Sequence steps
                  </MenuItem>
                )}
                  <MenuItem
                    key={'participantType'}
                    className={
                      selectedSetting === 'participantType' ? 'selected' : ''
                    }
                    onClick={() => selectSetting('participantType')}
                    button
                  >
                    Participant Type
                  </MenuItem>
              </MenuList>
            </Grid>
            <Grid className="settings-content" item xs={8}>
              <div className="settings-content-title">
                {startCase(selectedSetting?.itemSettings?.type)}
              </div>
              {selectedSetting && selectedSetting?.itemKey === 'numeric' && (
                <div className="silder-element">
                  <Slider
                    key={`slider-${selectedSetting.itemSettings?.type}`}
                    value={selectedValue}
                    aria-labelledby="discrete-slider-always"
                    max={selectedSetting.itemSettings.max}
                    min={selectedSetting.itemSettings.min}
                    step={selectedSetting.itemSettings.jump}
                    onChange={(event, value) => {
                      handleSliderChange({
                        value,
                        type: selectedSetting.itemSettings.type,
                      });
                    }}
                    valueLabelDisplay="on"
                    disabled={disable}
                  />
                </div>
              )}
              {selectedSetting && selectedSetting?.itemKey === 'enum' && (
                <div>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="settings-select"
                    onChange={handleChange}
                    value={selectedEnumValue()}
                    disabled={disable}
                  >
                    {selectedSetting?.itemSettings?.values.map((val) => {
                      return (
                        <MenuItem key={`select-${val.key}`} value={val.key}>
                          {val.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {numericKeys.length &&
                    numericKeys.map((numericKey, i) => {
                      const parsedKey = numericKey
                        .split('')
                        .filter((char) => !Number(char))
                        .map((x, index) => (index === 0 ? x.toUpperCase() : x))
                        .join('');
                      return (
                        <Fragment key={i}>
                          {numericKey !== 'duration1' ? (
                            <div className="slider-group">
                              <label>{parsedKey}</label>
                              <Slider
                                className="numeric-settings"
                                key={`slider-${settings.settings.numeric[numericKey].type}`}
                                value={sliderValue(
                                  activity.settings.fixed[
                                    settings.settings.numeric[numericKey].type
                                  ],
                                  settings.settings.numeric[numericKey].start,
                                  settings.settings.numeric[numericKey].isTimed,
                                )}
                                aria-labelledby="discrete-slider-always"
                                track="normal"
                                max={settings.settings.numeric[numericKey].max}
                                min={settings.settings.numeric[numericKey].min}
                                step={
                                  settings.settings.numeric[numericKey].jump
                                }
                                valueLabelDisplay="on"
                                onChange={(event, newValue) =>
                                  handleNumericKey(
                                    event,
                                    settings.settings.numeric[numericKey]
                                      .isTimed
                                      ? newValue * 1000
                                      : newValue,
                                    settings.settings.numeric[numericKey].type,
                                  )
                                }
                                disabled={disable}
                              />
                            </div>
                          ) : (
                            <>
                              <div className="slider-group">
                                <label>Minutes</label>
                                <Slider
                                  className="numeric-settings"
                                  key={`slider-${settings.settings.numeric.duration1.type}`}
                                  value={calculateTime().minutes}
                                  aria-labelledby="discrete-slider-always"
                                  track="normal"
                                  max={calculateTime().seconds > 0 ? 59 : 60}
                                  min={0}
                                  valueLabelDisplay="on"
                                  onChange={(event, newValue) => {
                                    handleTimeChange({ minutes: newValue });
                                  }}
                                  disabled={disable}
                                />
                              </div>
                              <div className="slider-group">
                                <label>Seconds</label>
                                <Slider
                                  className="numeric-settings"
                                  key={`slider-${settings.settings.numeric[numericKey].type}`}
                                  value={calculateTime().seconds}
                                  aria-labelledby="discrete-slider-always"
                                  track="normal"
                                  max={
                                    settings.settings.numeric.duration1.max /
                                      60 -
                                    1
                                  }
                                  min={0}
                                  step={
                                    settings.settings.numeric[numericKey].jump
                                  }
                                  valueLabelDisplay="on"
                                  onChange={(event, newValue) => {
                                    handleTimeChange({ seconds: newValue });
                                  }}
                                  disabled={disable}
                                />
                              </div>
                            </>
                          )}
                        </Fragment>
                      );
                    })}
                </div>
              )}
              {selectedSetting === 'sequenceSteps' && (
                <Grid container spacing={3}>
                  {[...Array(activity?.settings?.fixed?.steps).keys()].map(
                    (value, index) => (
                      <Grid item xs={3}>
                        <Card className="step-box-container">
                          <div className="step-box-title">
                            Step #{index + 1}
                          </div>
                          <FormControl className="step-box-select-name">
                            <InputLabel id="demo-simple-select-label">
                              Which pod*
                            </InputLabel>
                            <Select
                              disabled={disable}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={sequencePodsValue(index)}
                              onChange={(event) =>
                                handleChangePodName(event, index)
                              }
                            >
                              {[
                                ...Array(
                                  activity?.settings?.fixed?.pods,
                                ).keys(),
                              ].map((value, index) => (
                                <MenuItem value={value}>
                                  Pod #{value + 1}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl className="step-box-select-color">
                            <InputLabel id="demo-simple-select-label">
                              Color*
                            </InputLabel>
                            <Select
                              disabled={disable}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={colorsValue(index)}
                              onChange={(event) =>
                                handleChangePodColor(event, index)
                              }
                            >
                              {Object.values(COLORS).map((value, index) => (
                                <MenuItem key={value.key} value={value}>
                                  <span
                                    style={{ color: value.hex }}
                                    className="color-name"
                                  >
                                    {value.name}
                                  </span>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Card>
                      </Grid>
                    ),
                  )}
                  </Grid>
                )}
              {selectedSetting === 'participantType' && (
                <Grid container spacing={3}>
                   <Grid item xs={3}>
                        <div className="settings-content-title">
                          {startCase(selectedSetting)}
                        </div>
                        <br></br>
                        <ToggleButtonGroup
                            color="primary"
                            value={selectedParticipantType}
                            exclusive
                            onChange={handleChangeParticipantType}
                            aria-label="participantType">
                            <ToggleButton value="PLAYER">Player</ToggleButton>
                            <ToggleButton value="TEAM">Team</ToggleButton>
                          </ToggleButtonGroup>
                      </Grid>
                    </Grid>
                    )}
            </Grid>
          </Grid>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

export default ActivitySettings;

function getActivityMergedFixed({ activity, type, value }) {
  const data = {
    ...activity,
    settings: {
      ...activity.settings,
      fixed: {
        ...activity.settings.fixed,
        [type]: value,
      },
    },
  };
  return data;
}
function getNumericKey({ dependents, value }) {
  const numericKeys =
    dependents
      .filter(({ ifValues }) => ifValues.includes(value))
      .map(({ numericKey }) => numericKey) || [];
  return numericKeys;
}
