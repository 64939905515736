/* ---------------------------------- const --------------------------------- */
export const UPLOAD_STATUS_STATES = {
  PENDING: 'PENDING',
  IN_PROCESS: 'IN_PROCESS',
  SUCCESS: 'SUCCESS',
  PAUSED: 'PAUSED',
  ERROR: 'ERROR',
  CANCELED: 'CANCELED',
};

export function typeToProp(type) {
  if (typeof type !== 'string') return;
  if (!type.split('').includes('/')) return;
  return type.split('/').join('_');
}
export function propToType(prop) {
  if (typeof prop !== 'string') return;
  if (!prop.split('').includes('_')) return;
  return prop.split('_').join('/');
}
export function checkIfCover(type) {
  return type.split('/').includes('cover');
}
