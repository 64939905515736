import { ActivityFixedSettings } from "./activity-fixed-settings";

/**@property {ActivityFixedSettings} fixed*/
export class ActivitySettingsModel {
  fixed;
  constructor(fixed = new ActivityFixedSettings()) {
    this.fixed = fixed;
  }
}


