import { storage, UploadErrors } from '../fireApi';
import { UPLOAD_STATUS_STATES } from '../utils/mediaUtils';
import { appendToFilename } from '../utils/stringHelpers'
import FileUtil from '../utils/FileUtil';

export function uploadFile(file, folderId, { tasksState, setTasksState }) {
  const handleMissingPropError = (property) => {
    throw new Error(
      `Your file object needs to contain a {${property}} property.`,
    );
  };
  const { type, local_id, fileName } = file;
  if (!fileName) return handleMissingPropError('fileName: string');
  if (!local_id) return handleMissingPropError('local_id: string');
  if (!type) return handleMissingPropError('type: string');
  if (!file.url) return handleMissingPropError('url: string');

  const metadata = { contentType: type };
  const fileToUpload = FileUtil.base64StringToFile(file.url, fileName);
  const storageRef = storage.ref();

  return new Promise((resolve, reject) => {
    const uploadTask = storageRef
      .child(
        `/content/fixed_activities_assets/${folderId}/${appendToFilename(fileName,`_${local_id}`)}`,
      )
      .put(fileToUpload, metadata);

    setTasksState({
      [fileName]: {
        uploadTask,
        uploadStatus: UPLOAD_STATUS_STATES.IN_PROCESS,
        uploadProgress: 0,
      },
    });

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const uploadProgress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );
        console.group('Uploading');
        console.log('Upload is ' + uploadProgress + '% done');
        console.log(snapshot.state);
        console.groupEnd();

        switch (snapshot.state) {
          case 'paused':
            setTasksState({
              [fileName]: {
                ...tasksState[fileName],
                uploadProgress,
                uploadStatus: UPLOAD_STATUS_STATES.PAUSED,
              },
            });
            break;
          case 'running':
            setTasksState({
              [fileName]: {
                ...tasksState[fileName],
                uploadProgress,
              },
            });
            break;
          default:
            break;
        }
      },
      (uploadError) => {
        console.log(uploadError);

        if (uploadError.code === UploadErrors.CANCELED.code)
          setTasksState({
            [fileName]: {
              ...tasksState[fileName],
              uploadStatus: UPLOAD_STATUS_STATES.CANCELED,
              uploadError,
            },
          });
        else
          setTasksState({
            [fileName]: {
              ...tasksState[fileName],
              uploadStatus: UPLOAD_STATUS_STATES.ERROR,
              uploadError,
            },
          });
      },
      async () =>
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((newUrl) => resolve(newUrl))
          .catch((error) => reject(error)),
    );
  });
}
