import { ActivitySettingsModel } from './activity-settings';
import { ActivityProperties } from './activity-properties';

export class CreateActivityPayload {
  engine;
  filters;
  properties;
  settings;
  constructor(
    engine = '',
    filters = [],
    properties = new ActivityProperties(),
    settings = new ActivitySettingsModel(),
  ) {
    this.engine = engine;
    this.filters = filters;
    this.properties = properties;
    this.settings = settings;
  }
  static fromObject(data) {
    if (data == null) {
      throw new Error('No data provided.');
    }
    if (data.engine == null) {
      throw new Error('Missing a selection for engine.');
    }
    if (data.filters == null) {
      throw new Error('Missing a value for filters.');
    }
    if (data.properties == null) {
      throw new Error('Missing activity properties.');
    }
    if (data.settings == null) {
      console.log('No settings entered.');
    }
    return new CreateActivityPayload(
      data.engine,
      data.filters,
      data.properties,
      data.settings,
    );
  }
}
