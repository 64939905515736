import React, { useEffect, useState } from 'react';
import { Card } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import FilterButton from './FilterButton';
import { VISIBILITY } from '../../utils/consts';

const ActivitiesStats = (props) => {
  const { searchText, resetNewActivity, stats, filterByVisibility } = props;
  const [summary, setSummary] = useState({
    published: 0,
    unpublished: 0,
    drafts: 0,
  });
  const [activeFilter, setActiveFilter] = useState('');
  const searchTextDebounced = debounce(searchText, 250);
  const handleChange = (event) => {
    searchTextDebounced(event.target.value.toLowerCase());
  };

  useEffect(() => {
    sumData();
  }, [stats]);

  const sumData = () => {
    const data = { published: 0, unpublished: 0, drafts: 0 };
    stats.forEach((val) => {
      switch (val.properties.visibility) {
        case VISIBILITY.HIDDEN:
          ++data.unpublished;
          break;
        case VISIBILITY.PUBLIC:
          ++data.published;
          break;
        case VISIBILITY.DRAFT:
          ++data.drafts;
          break;
        default:
          break;
      }
      if (val.draft?.properties.visibility === VISIBILITY.DRAFT) {
        ++data.drafts;
      }
    });
    setSummary(data);
  };

  const filterFunc = (visibilityState) => {
    if (visibilityState === activeFilter) {
      filterByVisibility('');
      setActiveFilter('');
    } else {
      filterByVisibility(visibilityState);
      setActiveFilter(visibilityState);
    }
  };

  return (
    <Card className="activities-stats">
      <TextField
        id="outlined-basic"
        label="Search Activity"
        variant="outlined"
        onChange={handleChange}
      />

      <div className="summary-stats">
        {/* <Typography
          style={{ marginRight: '0.5em', marginLeft: '0.25em' }}
          variant="h3"
          component={() => <span className="">SHOW:</span>}
        /> */}
        <div className="stats-wrapper">
          <div className="stats-number">{summary.published}</div>
          <FilterButton
            filterState={activeFilter}
            filterName={VISIBILITY.PUBLIC}
            filterFunc={filterFunc}
          >
            Published
          </FilterButton>
        </div>
        <div className="stats-wrapper">
          <div className="stats-number">{summary.unpublished}</div>{' '}
          <FilterButton
            filterState={activeFilter}
            filterName={VISIBILITY.HIDDEN}
            filterFunc={filterFunc}
          >
            Unpublished
          </FilterButton>
        </div>
        <div className="stats-wrapper">
          <div className="stats-number">{summary.drafts}</div>
          <FilterButton
            filterState={activeFilter}
            filterName={VISIBILITY.DRAFT}
            filterFunc={filterFunc}
          >
            Drafts
          </FilterButton>
        </div>
      </div>
      <Button
        className="create-activity-button"
        variant="contained"
        color="primary"
        component={Link}
        to={'/new-activity'}
        onClick={() => {
          resetNewActivity();
        }}
      >
        Create Activity
      </Button>
    </Card>
  );
};

export default ActivitiesStats;
