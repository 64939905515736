import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    cursor: 'pointer',
  },
  grow: {
    flexGrow: 1,
  },
  logout: {
    position: 'absolute',
    right: '20px',
    textAlign: 'right',
  },
}));

export default useStyles;
