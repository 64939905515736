import * as firebase from 'firebase/app';

// DEV
const configDev = {
  apiKey: 'AIzaSyBV79MqtNteP2D__xIxR5ZOZUpIYb5TcsA',
  authDomain: 'blazepod-development.firebaseapp.com',
  databaseURL: 'https://blazepod-development.firebaseio.com',
  projectId: 'blazepod-development',
  storageBucket: 'blazepod-development.appspot.com',
  messagingSenderId: '82230290514',
  appId: '1:82230290514:web:2dccad596fad7ed434978b',
};

// PROD
const configProd = {
  apiKey: 'AIzaSyA9711E8keMQU7akb8Ws7K_LeICHMtBcvk',
  authDomain: 'blazepod-apps-85c6f.firebaseapp.com',
  databaseURL: 'https://blazepod-apps-85c6f.firebaseio.com',
  projectId: 'blazepod-apps-85c6f',
  storageBucket: 'blazepod-apps-85c6f.appspot.com',
  messagingSenderId: '505971713713',
  appId: '1:505971713713:web:d634ccd9de62ef311f6490',
  measurementId: 'G-T9T3YRD9FD',
};

const getConfig = () => {
  switch(process.env.REACT_APP_DEPLOY_ENV) {
    case 'development': 
      return configDev;
    case 'production':
      return configProd
    default:
      return configDev;
  }
}

const firebaseApp = firebase.initializeApp(getConfig());

export const fireAuth = firebaseApp.auth();
export const storage = firebaseApp.storage();
export const firestore = firebaseApp.firestore();
export const authProvider = new firebase.auth.GoogleAuthProvider();
const STORAGE = 'storage/';
export class UploadErrors {
  /**
   * An unknown error occurred
   */
  static UNKNOWN = {
    code: `${STORAGE}unknown`,
    message: 'An unknown error occurred',
  };

  /**
   * An unknown error occurred
   */
  static OBJECT_NOT_FOUND = {
    code: `${STORAGE}object-not-found`,
    message: 'An unknown error occurred',
  };

  /**
   * No bucket is configured for cloud storage
   */
  static BUCKET_NOT_FOUND = {
    code: `${STORAGE}bucket-not-found`,
    message: 'No bucket is configured for cloud storage',
  };

  /**
   * No project is configured for cloud storage
   */
  static PROJECT_NOT_FOUND = {
    code: `${STORAGE}project-not-found`,
    message: 'No project is configured for cloud storage',
  };

  /**
   * Quota on your Cloud Storage bucket has been exceeded.
   * If you're on the free tier, upgrade to a paid plan.
   * If you're on a paid plan, reach out to Firebase support.
   */
  static QUOTA_EXCEEDED = {
    code: `${STORAGE}quota-exceeded`,
    message: `Quota on your Cloud Storage bucket has been exceeded. If you're on the free tier, upgrade to a paid plan. If you're on a paid plan, reach out to Firebase support.`,
  };

  /**
   * User is unauthenticated, please authenticate and try again.
   */
  static UNAUTHENTICATED = {
    code: `${STORAGE}unauthenticated`,
    message: 'User is unauthenticated, please authenticate and try again.',
  };

  /**
   * User is not authorized to perform the desired action,
   * check your security rules to ensure they are correct.
   */
  static UNAUTHORIZED = {
    code: `${STORAGE}unauthorized`,
    message: `User is not authorized to perform the desired action,
  check your security rules to ensure they are correct.`,
  };

  /**
   * The maximum time limit on an operation
   * (upload, download, delete, etc.) has been exceeded.
   *  Try uploading again.
   */
  static RETRY_LIMIT_EXCEEDED = {
    code: `${STORAGE}retry-limit-exceeded`,
    message: `The maximum time limit on an operation
  (upload, download, delete, etc.) has been exceeded.
   Try uploading again.`,
  };

  /**
   * File on the client does not match the checksum of the file received by the server. Try uploading again.
   */
  static INVALID_CHECKSUM = {
    code: `${STORAGE}invalid-checksum`,
    message: `File on the client does not match the checksum of the file received by the server. Try uploading again.`,
  };

  /**
   * User canceled the operation
   */
  static CANCELED = {
    code: `${STORAGE}canceled`,
    message: `User canceled the operation`,
  };

  /**
   * Invalid event name provided. Must be one of [`running`, `progress`, `pause`]
   */
  static INVALID_EVENT_NAME = {
    code: `${STORAGE}invalid-event-name`,
    message:
      'Invalid event name provided. Must be one of [`running`, `progress`, `pause`]',
  };

  /**
   * Invalid URL provided to refFromURL().
   * Must be of the form:
   * gs://bucket/object or https://firebasestorage.googleapis.com/v0/b/bucket/o/object?token=<TOKEN>
   */
  static INVALID_URL = {
    code: `${STORAGE}invalid-url`,
    message: `Invalid URL provided to refFromURL().
  Must be of the form:
  gs://bucket/object or https://firebasestorage.googleapis.com/v0/b/bucket/o/object?token=<TOKEN>`,
  };

  /**
   * The argument passed to put() must be `File`, `Blob`, or `UInt8` Array.
   * The argument passed to putString() must be a raw, `Base64`, or `Base64URL` string.
   */
  static INVALID_ARGUMENT = {
    code: `${STORAGE}invalid-argument`,
    message:
      'The argument passed to put() must be `File`, `Blob`, or `UInt8` Array. The argument passed to putString() must be a raw, `Base64`, or `Base64URL` string.',
  };
  /**
   * No bucket has been set in your config's storageBucket property.
   */
  static NO_DEFAULT_BUCKET = {
    code: `${STORAGE}no-default-bucket`,
    message: "No bucket has been set in your config's storageBucket property.",
  };
  /**
   * Commonly occurs when the local file has changed (deleted, saved again, etc.). Try uploading again after verifying that the file hasn't changed.
   */
  static CANNOT_SLICE_BLOB = {
    code: `${STORAGE}cannot-slice-blob`,
    message:
      "Commonly occurs when the local file has changed (deleted, saved again, etc.). Try uploading again after verifying that the file hasn't changed.",
  };
  /**
   * File on the client does not match the size of the file received by the server. Try uploading again.
   */
  static SERVER_FILE_WRONG_SIZE = {
    code: `${STORAGE}server-file-wrong-size`,
    message:
      'File on the client does not match the size of the file received by the server. Try uploading again.',
  };
}
