import axios from 'axios';
import qs from 'qs';
import { store } from '../';
import * as userSelectors from '../store/user/reducer';


const API_URL = process.env.REACT_APP_API_ROOT;

class RTApiService {
  async defaultRequest(path, method, data, useAuth = true) {
    const headers = {};

    if (useAuth && store) {
      const state = store.getState();
      const token =
        userSelectors.getUserToken(state) || localStorage.getItem('token');
      headers.Authorization = `Bearer ${token}`;
    }
    const query = method === 'get' ? `?${qs.stringify(data)}` : '';
    const url = `${API_URL}${path}${query}`;
    try {
      const result = await axios({ method, url, data, headers });
      return result.data;
    } catch (err) {
      throw err;
    }
  }

  async checkPrivileges() {
    return this.defaultRequest('/v1/cms/privileges', 'get');
  }

  async getSettings() {
    return this.defaultRequest('/v1/common/settings', 'get');
  }

  async getFilters() {
    return this.defaultRequest('/v1/common/filters', 'get');
  }

  async fetchAllActivities() {
    return this.defaultRequest('/v1/cms/activities', 'get');
  }

  async getActivity(id) {
    return this.defaultRequest(`/v1/cms/activity/${id}`, 'get');
  }

  async deleteActivity(id) {
    return this.defaultRequest(`/v1/cms/activity/${id}`, 'delete');
  }

  async publishActivity(id) {
    return this.defaultRequest(`/v1/cms/activity/${id}/publish`, 'put');
  }

  async unpublishActivity(id) {
    return this.defaultRequest(`/v1/cms/activity/${id}/unpublish`, 'put');
  }

  //* ?? WHAT IS THE DIFFERENCE BETWEEN THIS AND "editNewUntil" ??
  async editActivity(obj) {
    return this.defaultRequest(
      `/v1/cms/activity/${obj.properties.id}/edit`,
      'put',
      { new_until: obj.properties.new_until },
    );
  }

  async editNewUntil(obj) {
    return this.defaultRequest(
      `/v1/cms/activity/${obj.properties.id}/edit`,
      'put',
      { new_until: obj.properties.new_until },
    );
  }

  async updateActivity(data) {
    delete data.properties.updated_at;
    return this.defaultRequest(
      `/v1/cms/activity/${data.properties.id}`,
      'put',
      data,
    );
  }

  async createActivity(data) {
    return this.defaultRequest(`/v1/cms/activity`, 'post', data);
  }
}

export default new RTApiService();
