import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import './index.css';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ToastContainer } from 'react-toastify';

import * as reducers from './store/reducers';
import Home from './containers/Home';
import Login from './containers/Login';
import PrivateRoute from './components/PrivateRoute';
import Activity from './containers/SingleActivityViews/UpdateActivity';
import CreateActivity from './containers/SingleActivityViews/CreateActivity';
import Layout from './containers/Layout/Layout';

import history from './history';

import 'react-toastify/dist/ReactToastify.css';

const persistConfig = {
  key: 'freqdashboard',
  storage,
};

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const pReducer = persistReducer(persistConfig, combineReducers(reducers));

const store = createStore(pReducer, enhancer);
const persistor = persistStore(store);

export { store };

const App = () => (
  <>
    {/* <Suspense fallback={<div>Loading...</div>}> */}
    <ToastContainer autoClose={5000} hideProgressBar />
    <Provider store={store}>
      <PersistGate
        // loading={() => <h2>Loading... Please wait :)</h2>}
        persistor={persistor}
      >
        <Router history={history}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Layout>
              <PrivateRoute exact path="/" component={Home} />
              <PrivateRoute exact path="/activity/:id" component={Activity} />
              <PrivateRoute
                exact
                path="/new-activity"
                component={CreateActivity}
              />
            </Layout>
            <Route
              component={() => (
                <h1 className="error404">Oops! 404 - Page Not Found.</h1>
              )}
            />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
    {/* </Suspense> */}
  </>
);

ReactDOM.render(<App />, document.getElementById('root'));
