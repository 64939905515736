import React, {forwardRef, useImperativeHandle} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmDialog = forwardRef((props,ref)=> {
  const {bodyText, confirmText = 'Confirm', cancelText = 'Cancel', title, confirmed, datePicker, input} = props
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState();

  useImperativeHandle(ref, () => ({
    openDialog(value) {
      setValue(value)
      setOpen(true)
    },
    openDatePicker(value) {
      setValue(value)
      setOpen(true)
    },
    deleteRef(value) {
      setValue(value)
      setOpen(true)
    }
  }));

  const handleConfirm = ()=>{
    confirmed(value)
    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {bodyText}
          </DialogContentText>
          <div>
            {input ?? datePicker}
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {cancelText}
          </Button>
          <Button onClick={handleConfirm} color="primary">
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
})

export default ConfirmDialog
