export const ACTIVITY_CONTROLLER_STATES = {
  PENDING_SUBMIT: 'pending_submit',
  INITIALIZING: 'initializing',
  SAVING_ACTIVITY: 'saving_activity',
  SAVE_ERROR: 'save_error',
  ACTIVITY_SAVED: 'activity_saved',
  UPLOADING_FILES: 'uploading_files',
  UPLOADING_COVER_IMAGES: 'uploading_cover_images',
  COVER_IMAGES_UPLOADED: 'cover_images_uploaded',
  UPLOADING_MEDIA_FILES: 'uploading_media_files',
  MEDIA_FILES_UPLOADED: 'media_files_uploaded',
  ALL_FILES_UPLOADED: 'all_files_uploaded',
  UPDATING_ACTIVITY: 'updating_activity',
  UPDATE_ERROR: 'update_error',
  ACTIVITY_UPDATED: 'activity_updated',
  PUBLISHING_ACTIVITY: 'publishing_activity',
  PUBLISH_ERROR: 'publish_error',
  ACTIVITY_PUBLISHED: 'activity_published',
  UNPUBLISHING_ACTIVITY: 'unpublishing_activity',
  UNPUBLISH_ERROR: 'unpublish_error',
  ACTIVITY_UNPUBLISHED: 'activity_unpublished',
};

export const UPLOAD_CONTROLLER_STATES = {
  PENDING: 'pending',
  INITIALIZING: 'initializing',
  UPLOADING_FILE: 'uploading_file',
  FILE_UPLOADED: 'file_uploaded',
  UPLOAD_ERROR: 'upload_error',
};

export const PAGE_ROLES = {
  CREATE_ACTIVITY: 'CREATE_ACTIVITY',
  EXISTING_ACTIVITY: 'EXISTING_ACTIVITY',
};

/* -------------------------------------------------------------------------- */
/* -------------------------------- functions ------------------------------- */
/**
 *
 * @param {string} _string a string with spaces.
 * @returns {string }string with underscores instead of spaces.
 */
export function spacesToUnderScore(_string) {
  if (_string.length < 1) return _string;
  const stringArray = _string.split(' ');
  if (!stringArray.length > 1) return _string;
  return stringArray.join('_');
}
