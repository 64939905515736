import React from 'react';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const PureLogin = (props) => {
  const { login } = props;

  return (
    <Card className="login" variant="outlined">
      <CardContent>
        <div className="title">BlazePod CMS</div>
      </CardContent>
      <CardActions>
        <Button className="login-button" onClick={login}>
          Google Login
        </Button>
      </CardActions>
    </Card>
  );
};

export default PureLogin;
