import React, { useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { PAGE_ROLES } from './utils';

const ENGINES = ['allAtOnce', 'focus', 'homeBase', 'random', 'sequence'];

const ActivityEngine = (props) => {
  const { activity, settings ,changeEngine, pageRole } = props;
  const [engine, setEngine] = React.useState(activity?.engine);
  const [initial, setInitial] = React.useState(true);

  useEffect(() => {
    if (activity) {
      setEngine(activity.engine);
      if (initial && pageRole === PAGE_ROLES.CREATE_ACTIVITY) {
        activity.settings.fixed = updateSetting(engine);
        setInitial(false)
      }
    }
  }, [activity]);

  const handleChange = (event) => {
    const newEngine = event.target.value;
    setEngine(newEngine);
    changeEngine(newEngine);
    activity.settings.fixed = updateSetting(newEngine);
  };

  const updateSetting = (activityEngine) => {
    let fixed = {};
    const settingsKeys = settings.engines[activityEngine];
    settingsKeys.items.forEach(
      element => { 
        fixed = {...fixed,...extractKeysProperty(element, settings)}
      });
    fixed.cycles = null
    fixed.stations = null
    fixed.delayCycles = null
    return fixed;
  }

  const extractKeysProperty = (element, settings) => {
    let properties = {}
    const elementSetting = settings.settings.enum[element] || settings.settings.numeric[element];
    const elementDependecies = settings.settings.enum[element]?.dependents || settings.settings.numeric[element]?.dependents;
    properties[elementSetting.type] = elementSetting.start || elementSetting.values[elementSetting.initial].key;
    if (elementDependecies) {
      elementDependecies.forEach(dep => {
        properties = {...properties, ...extractKeysProperty(dep.numericKey, settings)}
      });
    }
    return properties;
  }

  return (
    <Accordion className="activity-card">
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <span className="activity-card-title">Activity Engine *</span>
        <Select
          labelId="engine"
          id="engine"
          className="engine-select"
          value={engine}
          onChange={handleChange}
          disabled={props.disable || pageRole === PAGE_ROLES.EXISTING_ACTIVITY}
        >
          {ENGINES.map((e) => (
            <MenuItem key={e} value={e}>
              {e}
            </MenuItem>
          ))}
        </Select>
      </AccordionSummary>
    </Accordion>
  );
};

export default ActivityEngine;

