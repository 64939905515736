const atob = (str) => Buffer.from(str, 'base64').toString('binary');
// const btoa = (str) => Buffer.from(str, 'binary').toString('base64');

/**
 * A utility class for Image management
 *  based on JavaScript Functions for Images and Files
 * Author: Justin Mitchel
 * Source: https://kirr.co/ndywes
 */

class FileUtil {
  /**
   * Convert a file to a base64 string;
   * @param file as File
   * @returns base64 as string
   */
  static getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', () => resolve(reader.result));
      reader.addEventListener('error', (error) => reject(error));
    });
  }

  /**
   * Convert a Base64-encoded string to a File object
   * @param {*string} base64String
   * @param {*string} filename
   * @returns {*File} A file from the Base64-string
   */
  static base64StringToFile(base64String, filename) {
    let arr = base64String.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  /**
   * Download a Base64-encoded file
   * @param {*File} base64Data
   * @param {*string} filename
   * @returns {*void}
   */
  static downloadBase64File(base64Data, filename) {
    let element = document.createElement('a');
    element.setAttribute('href', base64Data);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  /**
   * Extract a Base64 Image's File Extension
   * @param {*File} base64Data
   * @returns {*string} File type
   */
  static extractImageFileTypeFromBase64(base64Data) {
    console.log(
      base64Data.substring('data:'.length, base64Data.indexOf(';base64')),
    );
    return base64Data.substring(
      'data:image/'.length,
      base64Data.indexOf(';base64'),
    );
  }

  /**
   * Base64 Image to Canvas with a Crop
   * @param {*MutableRefObject<Canvas>} canvasRef A reference to a canvas element;(If not using ReactJS switch to document.createElement('canvas'))
   * @param {*string} image64 Base64 Image
   * @param {*{x:number; y:number; width:number; height:number}} pixelCrop Crop the Image previewed on the canvas
   * @returns {*void}
   */
  static image64toCanvasRef(canvasRef, image64, pixelCrop) {
    const canvas = canvasRef; // document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = image64;
    image.onload = function () {
      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height,
      );
    };
  }
}
export default FileUtil;
/* 
// Convert a Base64-encoded string to a File object
export function base64StringToFile(base64String, filename) {
  let arr = base64String.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

// Download a Base64-encoded file

export function downloadBase64File(base64Data, filename) {
  let element = document.createElement('a');
  element.setAttribute('href', base64Data);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

// Extract an Base64 Image's File Extension
export function extractImageFileExtensionFromBase64(base64Data) {
  return base64Data.substring(
    'data:image/'.length,
    base64Data.indexOf(';base64'),
  );
}

// Base64 Image to Canvas with a Crop
export function image64toCanvasRef(canvasRef, image64, pixelCrop) {
  const canvas = canvasRef; // document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');
  const image = new Image();
  image.src = image64;
  image.onload = function () {
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );
  };
}
 */
