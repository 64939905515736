import * as types from './actionTypes';
import * as usersActions from '../user/actions';
import apiService from '../../services/rtApi';
import { VISIBILITY } from '../../utils/consts';
import httpStatus from 'http-status';
import { typeToProp } from '../../utils/mediaUtils';
import { activities } from '../reducers';

const handleApiError = ({ error, dispatch }) => {
  const errorInfo = error?.response?.data;
  dispatch({
    type: types.GET_ACTIVITY_ERROR,
    error: errorInfo || error,
  });
  if (errorInfo?.statusCode === httpStatus.UNAUTHORIZED) {
    dispatch(usersActions.googleLogout());
  }
};

export function fetchAllActivities() {
  return async (dispatch) => {
    try {
      const { payload } = await apiService.fetchAllActivities();
      dispatch({
        type: types.GET_ALL_ACTIVITIES_SUCCESSFUL,
        payload,
      });
    } catch (err) {
      handleApiError({ error: err, dispatch });
    }
  };
}

export function getActivity(id) {
  return async (dispatch) => {
    try {
      const { payload } = await apiService.getActivity(id);
      dispatch({
        type: types.GET_ACTIVITY_SUCCESSFUL,
        payload,
      });
    } catch (err) {
      handleApiError({ error: err, dispatch });
    }
  };
}

export function editActivityApi(id) {
  return async (dispatch) => {
    try {
      await apiService.editActivity(id);
      dispatch(fetchAllActivities());
    } catch (err) {
      let error = err;
      try {
        error = err.response.data;
      } catch (e) {}
      dispatch({
        type: types.GET_ACTIVITY_ERROR,
        error,
      });
      if (error.error === 'Unauthorized') {
        dispatch(usersActions.googleLogout());
      }
    }
  };
}

export function editNewUntil(obj) {
  return async (dispatch) => {
    try {
      await apiService.editNewUntil(obj);
      dispatch(fetchAllActivities());
    } catch (err) {
      handleApiError({ error: err, dispatch });
    }
  };
}

export function editActivity(obj) {
  return async (dispatch) => {
    try {
      const payload = obj;
      dispatch({
        type: types.EDIT_ACTIVITY_SUCCESSFUL,
        payload,
      });
    } catch (err) {
      handleApiError({ error: err, dispatch });
    }
  };
}

export function deleteActivity(id) {
  return async (dispatch) => {
    try {
      await apiService.deleteActivity(id);
      dispatch(fetchAllActivities());
    } catch (err) {
      handleApiError({ error: err, dispatch });
    }
  };
}

export function updateActivity(data) {
  return async (dispatch) => {
    // console.log('updateActivity Called');
    // console.log(data);
    return new Promise((resolve, reject) =>
      apiService
        .updateActivity(data)
        .then(({ payload }) => {
          console.log('updateActivity returned payload');
          dispatch(fetchAllActivities());
          dispatch({
            type: types.UPDATE_ACTIVITY_SUCCESSFUL,
            payload,
          });
          resolve(payload);
        })
        .catch((error) => {
          console.log(error);
          handleApiError({ error, dispatch });
          reject(error);
        }),
    );
  };
}

export function getSettings() {
  return async (dispatch) => {
    try {
      const { payload } = await apiService.getSettings();
      dispatch({
        type: types.GET_ACTIVITY_SETTINGS_SUCCESSFUL,
        payload,
      });
      dispatch(getFilters());
    } catch (err) {
      handleApiError({ error: err, dispatch });
    }
  };
}

export function getFilters() {
  return async (dispatch) => {
    try {
      const { payload } = await apiService.getFilters();
      dispatch({
        type: types.GET_ACTIVITY_FILTERS_SUCCESSFUL,
        payload,
      });
    } catch (err) {
      handleApiError({ error: err, dispatch });
    }
  };
}

export function publishActivity(id) {
  return async (dispatch) => {
    try {
      const { payload } = await apiService.publishActivity(id);
      dispatch({
        type: types.GET_ACTIVITY_SUCCESSFUL,
        payload,
      });
    } catch (err) {
      handleApiError({ error: err, dispatch });
    }
  };
}

export function unpublishActivity(id) {
  return async (dispatch) => {
    try {
      const { payload } = await apiService.unpublishActivity(id);
      dispatch({
        type: types.GET_ACTIVITY_SUCCESSFUL,
        payload,
      });
    } catch (err) {
      handleApiError({ error: err, dispatch });
    }
  };
}

export function resetActivity(id) {
  return async (dispatch) => {
    dispatch(getActivity(id));
  };
}

export function newActivity() {
  return async (dispatch) => {
    dispatch({ type: types.NEW_ACTIVITY_SUCCESSFUL });
    // history.push('/activity');
  };
}

export function selectActivity(id) {
  return async (dispatch) => {
    dispatch({
      type: types.SELECT_ACTIVITY,
      payload: id,
    });
  };
}

export function selectAllActivities(list) {
  return async (dispatch) => {
    dispatch({
      type: types.SELECT_ALL_ACTIVITIES,
      payload: list,
    });
  };
}

export function deleteBulkActivities(list) {
  return async (dispatch) => {
    Object.entries(list).map(async ([id]) => {
      await apiService.deleteActivity(id);
    });

    dispatch(fetchAllActivities());
  };
}

export function visibleBulkActivities(selected, list) {
  return async (dispatch) => {
    const selectedList = list.filter(({ properties }) => {
      return selected[properties.id];
    });

    await selectedList.map(async (item) => {
      const updated = {
        ...item,
        properties: {
          ...item.properties,
          visibility:
            item.properties.visibility === VISIBILITY.PUBLIC
              ? VISIBILITY.HIDDEN
              : VISIBILITY.PUBLIC,
        },
      };
      await apiService.updateActivity(updated);
    });
    dispatch(fetchAllActivities());
  };
}

export function markBulkAsNewSelected(selected, list, date) {
  return async (dispatch) => {
    const selectedList = list.filter(({ properties }) => {
      return selected[properties.id];
    });
    selectedList.map(async (item) => {
      const updated = {
        ...item,
        properties: {
          ...item.properties,
          new_until: date,
        },
      };
      await apiService.updateActivity(updated);
    });
    dispatch(fetchAllActivities());
  };
}

export function newActivityChanged(payload) {
  return async (dispatch) => {
    dispatch({
      type: types.NEW_ACTIVITY_CHANGED,
      payload,
    });
  };
}

export function createActivity(data) {
  return async (dispatch) => {
    return new Promise((resolve, reject = (error) => console.log(error)) => {
      apiService
        .createActivity(data)
        .then(({ payload }) => {
          dispatch(fetchAllActivities()).then(() => {
            dispatch({
              type: types.NEW_ACTIVITY_CREATED_SUCCESSFUL,
              payload,
            });
            // console.log(payload);
            resolve(payload);
          });
        })
        .catch((error) => {
          handleApiError({ error, dispatch });
          reject(error);
        });
    });
  };
}

export function resetNewActivity() {
  return async (dispatch) => {
    dispatch({
      type: types.NEW_ACTIVITY_RESET,
      payload: {},
    });
  };
}

export function setActivityToEdit(activity) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_ACTIVITY_TO_EDIT,
      payload: activity,
    });
  };
}

export function setShowOriginalActivity(show) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_SHOW_ORIGINAL_ACTIVITY,
      payload: show,
    });
  };
}

export function setActivityCover_NEW(activity, cover) {
  return (dispatch) => {
    dispatch({
      type: types.SET_COVER_NEW,
      payload: {
        [typeToProp(cover.type)]: cover.url,
      },
    });
  };
}
export function setActivityCover(activity, cover) {
  return (dispatch) => {
    dispatch({
      type: types.SET_COVER,
      payload: {
        [typeToProp(cover.type)]: cover.url,
      },
    });
  };
}
export function setActivityMedia_NEW(activity, mediaItem) {
  return (dispatch) => {
    const { properties } = activity;
    const { media } = properties;

    dispatch({
      type: activities.SET_MEDIA_NEW,
      payload: {
        ...activity,
        properties: {
          ...properties,
          media: [...media, { type: mediaItem.type, url: mediaItem.url }],
        },
      },
    });
  };
}

export function setActivityMedia(activity, mediaItem) {
  return (dispatch) => {
    const { properties } = activity;
    const { media } = properties;

    dispatch({
      type: activities.SET_MEDIA,
      payload: {
        ...activity,
        properties: {
          ...properties,
          media: [...media, { type: mediaItem.type, url: mediaItem.url }],
        },
      },
    });
  };
}
