import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as activitiesActions from '../../../store/activites/actions';
import * as activitiesSelectors from '../../../store/activites/reducer';

import Activity from '../Activity/Activity.component';

import '../style.scss';

const mapDispatchToProps = (dispatch) => {
  const getActivity = (id) => dispatch(activitiesActions.getActivity(id));
  const resetActivity = (id) => dispatch(activitiesActions.resetActivity(id));
  const setShowOriginalActivity = (show) =>
    dispatch(activitiesActions.setShowOriginalActivity(show));
  return {
    getActivity,
    resetActivity,
    setShowOriginalActivity,
  };
};

const mapStateToProps = (state, { match: { params } }) => {
  const activity = activitiesSelectors.getActivity(state.activities, params);
  const settings = activitiesSelectors.getSettings(state);
  const filters = activitiesSelectors.getFilters(state);
  const showOriginalActivity = activitiesSelectors.getShowOriginal(state);
  const currentActivity = showOriginalActivity
    ? activity
    : activity.draft ?? activity;
  const title = `${currentActivity.properties.display_name}${
    activity.draft ? ' - draft' : ''
  }`;
  return {
    initialActivity: currentActivity,
    settings,
    filters,
    title,
    showOriginalActivity,
    originalActivity: activity,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Activity),
);
