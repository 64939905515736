export const CONSTS = {
  ACTIVITY_OBJ: {
    engine: 'random',
    settings: {
      fixed: {},
    },
    filters: [],
    properties: {
      type: '',
      display_name: '',
      description: '',
      cover_box: '',
      cover_rect: '',
      cover_small: '',
      media: [],
      tags: [],
    },
  },
};
