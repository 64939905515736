import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { pick } from 'lodash';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ConfirmDialog from '../../components/ConfirmDialog';
import { ActivityProperties } from '../../models/activity-properties';
import { CreateActivityPayload } from '../../models/create-activity-payload';
import rtApi from '../../services/rtApi';
import { VISIBILITY } from '../../utils/consts';
import BulkActionMenu from './BulkActionMenu';

// const VISIBILITY_OPACITY = { [VISIBILITY.PUBLIC]: 1, [VISIBILITY.HIDDEN]: 0.5 };
const NEW_OPACITY = { [false]: 1, [true]: 0.5 };

const atLeastOneSelected = (selected, list) => {
  return !!Object.entries(selected).filter(([id]) => {
    return !!list.filter(({ properties }) => properties.id === id).length;
  }).length;
};
const ActivitiesList = ({
  list = [],
  history,
  createActivity,
  updateActivity,
  deleteActivity,
  selectActivity,
  selectAllActivities,
  deleteBulkActivities,
  visibleBulkActivities,
  markBulkAsNewSelected,
  setActivityToEdit,
  fetchAllActivities,
  editNewUntil,
  activities,
}) => {
  const [date, setDate] = useState();
  const [displayActions, setDisplayActions] = useState(false);
  const [activityToDuplicate, setActivityToDuplicate] = useState(null);
  const [activityDisplayName, setActivityDisplayName] = useState('');
  const childRef = useRef();
  const datepickerRef = useRef();
  const deleteRef = useRef();
  const unpublishRef = useRef();
  const publishRef = useRef();
  const duplicateRef = useRef();

  const { selectedActivities, isAllSelected } = activities;

  const editActivity = (activity) => {
    setActivityToEdit(activity);
    history.push(`/activity/${activity.properties.id}`);
  };

  const changeHidden = ({ value, isBulk }) => {
    if (isBulk) {
      visibleBulkActivities(value, list);
    } else {
      const updated = {
        ...value,
        properties: {
          ...value.properties,
          visibility:
            value.properties.visibility === VISIBILITY.PUBLIC
              ? VISIBILITY.HIDDEN
              : VISIBILITY.PUBLIC,
        },
      };
      updateActivity(updated);
    }
  };

  const dateChange = (value) => {
    setDate(value.target.value);
  };
  const editDisplayName = (value, activity) => ({
    ...activity,
    properties: { ...activity.properties, display_name: value },
  });
  const duplicateDisplayNameChange = (e) => {
    setActivityDisplayName(e.currentTarget.value);
  };

  const changeNew = ({ value, isBulk }) => {
    if (isBulk) {
      markBulkAsNewSelected(value, list, date);
      return;
    }

    const updated = {
      ...value,
      properties: {
        ...value.properties,
        new_until: date,
      },
    };
    editNewUntil(updated);
    setDate('');
  };

  const deleteActivityFn = ({ value, isBulk }) => {
    isBulk ? deleteBulkActivities(value) : deleteActivity(value.properties.id);
  };

  const publish = async ({ value: _activity }) => {
    await rtApi.publishActivity(_activity.properties.id);
    await fetchAllActivities();
  };

  const unpublish = async ({ value: _activity }) => {
    await rtApi.unpublishActivity(_activity.properties.id);
    await fetchAllActivities();
  };
  const duplicate = async ({ value, isBulk }) => {
    createActivity(
      CreateActivityPayload.fromObject(
        editDisplayName(activityDisplayName, {
          ...value,
          properties: pick(
            value.properties,
            Object.keys(new ActivityProperties()),
          ),
        }),
      ),
    )
      .then((result) => {
        toast.success('Activity Duplicated.');
        console.log(result);
      })
      .catch((e) => console.log(e));
  };
  const handleDuplicateClick = () => {
    if (selectedActivities.length > 1) {
      toast.error('This action can only be done on one activity at a time.');
      return;
    }

    const activity = list.find(
      (act) => act.properties.id === Object.values(selectedActivities)[0],
    );
    if (activity == null) {
      toast.warn("Can't duplicate an activity which does not exist.");
      return;
    }
    const newActivity = editDisplayName(
      `${activity.properties.display_name}Copy`,
      activity,
    );
    setActivityToDuplicate(() => {
      setActivityDisplayName(newActivity.properties.display_name);
      return newActivity;
    });
    duplicateRef.current.openDialog({
      value: newActivity,
      isBulk: true,
    });

    setDisplayActions(false);
  };

  return (
    <Card className="activities">
      <List className="activities-list">
        <ListItem>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={isAllSelected}
              tabIndex={-1}
              disableRipple
              onChange={() => {
                selectAllActivities(
                  !isAllSelected
                    ? list.map(({ properties }) => properties?.id)
                    : [],
                );
              }}
            />
          </ListItemIcon>
          <ListItemText style={{ position: 'relative' }}>
            <Button
              disabled={!atLeastOneSelected(selectedActivities, list)}
              variant="contained"
              color="secondary"
              onClick={() => {
                setDisplayActions(!displayActions);
              }}
            >
              Actions
            </Button>
            {displayActions && (
              <BulkActionMenu
                selectedActivities={selectedActivities}
                deleteSelected={() => {
                  deleteRef.current.openDatePicker({
                    value: selectedActivities,
                    isBulk: true,
                  });
                  setDisplayActions(false);
                }}
                hideSelected={() => {
                  childRef.current.openDialog({
                    value: selectedActivities,
                    isBulk: true,
                  });
                  setDisplayActions(false);
                }}
                markAsNewSelected={() => {
                  datepickerRef.current.openDatePicker({
                    value: selectedActivities,
                    isBulk: true,
                  });
                  setDisplayActions(false);
                }}
                duplicateSelected={handleDuplicateClick}
              />
            )}
          </ListItemText>
        </ListItem>
        {list
          ?.sort((a, b) =>
            a.properties?.display_name < b.properties?.display_name ? -1 : 1,
          )
          .map((value, index) => {
            const { properties } = value;
            const { id } = properties || {};
            const labelId = `checkbox-list-label-${value}`;
            return (
              <ListItem
                key={index}
                role={undefined}
                dense
                button
                onClick={() => editActivity(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={!!selectedActivities[id]}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={(event) => {
                      event.stopPropagation();
                      selectActivity(id);
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={value?.properties?.display_name}
                />
                <ListItemSecondaryAction>
                  {value?.draft && (
                    <IconButton onClick={() => editActivity(value)}>
                      <BookmarkBorderIcon className="status-icon" />
                    </IconButton>
                  )}
                  {value.properties.visibility !== VISIBILITY.DRAFT && (
                    <>
                      {value.properties.visibility === VISIBILITY.HIDDEN ? (
                        <IconButton
                          onClick={() =>
                            publishRef.current.openDialog({
                              value,
                              isBulk: false,
                            })
                          }
                        >
                          <VisibilityOffIcon className="status-icon" />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() =>
                            unpublishRef.current.openDialog({
                              value,
                              isBulk: false,
                            })
                          }
                        >
                          <VisibilityIcon className="status-icon" />
                        </IconButton>
                      )}
                    </>
                  )}
                  {/* <IconButton
                    onClick={() =>
                      datepickerRef.current.openDatePicker({
                        value,
                        isBulk: false,
                      })
                    }
                    style={{ opacity: NEW_OPACITY[!value.properties.is_new] }}
                  >
                    <FiberNewIcon className="status-icon" />
                  </IconButton> */}
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => editActivity(value)}
                  >
                    <EditIcon className="status-icon" />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      deleteRef.current.openDatePicker({ value, isBulk: false })
                    }
                    edge="end"
                    aria-label="comments"
                  >
                    <DeleteIcon className="status-icon" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
      </List>
      <ConfirmDialog
        ref={childRef}
        confirmed={changeHidden}
        title="Are you sure?"
      />
      <ConfirmDialog
        ref={unpublishRef}
        confirmed={unpublish}
        title="Are you sure you want to unpublish?"
      />
      <ConfirmDialog
        ref={publishRef}
        confirmed={publish}
        title="Are you sure you want to publish?"
      />
      <ConfirmDialog
        ref={deleteRef}
        confirmed={deleteActivityFn}
        title="Are you sure that you want to delete?"
      />
      <ConfirmDialog
        ref={datepickerRef}
        datePicker={
          <TextField
            id="datetime-local"
            label="Set time"
            type="datetime-local"
            onChange={dateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        }
        confirmed={changeNew}
        title="Change new date"
      />
      <ConfirmDialog
        ref={duplicateRef}
        confirmText="Duplicate"
        input={
          <TextField
            id="duplicate"
            label="New Activity Name"
            type="text"
            onChange={duplicateDisplayNameChange}
            defaultValue={`${activityToDuplicate?.properties.display_name}`}
            InputLabelProps={{
              shrink: true,
            }}
          />
        }
        confirmed={duplicate}
        title="New Activity Name"
      />
    </Card>
  );
};

export default ActivitiesList;
