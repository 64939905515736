import React from 'react';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import { FileCopy } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    width: 230,
    position: 'absolute',
    zIndex: 1,
  },
});

export default function TypographyMenu({
  deleteSelected,
  hideSelected,
  markAsNewSelected,
  duplicateSelected,
  selectedActivities,
}) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <MenuList>
        <MenuItem onClick={deleteSelected}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Delete selected</Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <BookmarkBorderIcon />
          </ListItemIcon>
          <Typography variant="inherit">Bookmark selected</Typography>
        </MenuItem>
        <MenuItem onClick={hideSelected}>
          <ListItemIcon>
            <VisibilityOffIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Hide selected
          </Typography>
        </MenuItem>
        <MenuItem onClick={markAsNewSelected}>
          <ListItemIcon>
            <FiberNewIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            Mark as New
          </Typography>
        </MenuItem>
        {Object.values(selectedActivities).length === 1 && (
          <MenuItem onClick={duplicateSelected}>
            <ListItemIcon>
              <FileCopy fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Duplicate Activity
            </Typography>
          </MenuItem>
        )}
      </MenuList>
    </Paper>
  );
}
