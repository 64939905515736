import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { EXCLUDE_FILTERS } from '../../../utils/consts';

const ActivityFilters = (props) => {
  const { activity, filters, editActivity, disable } = props;

  const [selectedAssistant, setSelectedAssistant] = useState(convertNumberToAssistanc(activity?.settings?.fixed.assistant));

  const groups = useMemo(
    () => filters.groups?.filter(({ key }) => !EXCLUDE_FILTERS.includes(key)),
    [filters],
  );
  const filtersArray = useMemo(() => {
    const allFilters = [];
    groups.forEach((group) => {
      allFilters.push(
        ...group.items.map((item) => ({
          ...item,
          group: group.id,
        })),
      );
    });
    return allFilters;
  }, [groups]);


  const handleChangeAssistanc = (event, index) => {
    activity.settings.fixed.assistant = convertAssistancToNumber(index);
    setSelectedAssistant(index)
    editActivity(activity);
  };


  const selectedFiltersArray = useMemo(
    () => filtersArray.filter(({ id }) => activity.filters.includes(id)),
    [activity, filtersArray],
  );
  const unselectedFiltersArray = useMemo(
    () => filtersArray.filter(({ id }) => !activity.filters.includes(id)),
    [activity, filtersArray],
  );
  const handleCheckbox = ({ value, items, action }) => {
    const diff = items
      .filter((item) => !value.includes(item))
      .map(({ id }) => id);

    editActivity({
      ...activity,
      filters: [
        ...(action === 'remove-option'
          ? activity.filters.filter((item) => !diff.includes(item))
          : [...activity.filters, ...value.map(({ id }) => id)]),
      ].filter((item, index, list) => list.indexOf(item) === index),
    });
  };

  return (
    <Accordion className="activity-card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <span className="activity-card-title">Activity Filters</span>
        <span className="activity-card-sub-title">
          Set which filter will be applied for this activity
        </span>
      </AccordionSummary>
      <AccordionDetails className="activity-card activity-card-filter">
      <small >Assistant</small> <br></br>
        <ToggleButtonGroup
          color="primary"
          value={selectedAssistant}
          exclusive
          onChange={handleChangeAssistanc}
          aria-label="assistanc"
        >
            <ToggleButton value="WITHOUT">Without</ToggleButton>
            <ToggleButton value="WITH">With</ToggleButton>
          </ToggleButtonGroup>
        {groups?.map((group, index) => {
          const groupItems = filtersArray.filter(
            (item) => item.group === group.id,
          );
          return (
            <div key={`${group?.id}-${index}`}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={unselectedFiltersArray.filter(
                  (filter) => filter.group === group.id,
                )}
                disableCloseOnSelect
                value={selectedFiltersArray.filter(
                  (filter) => filter.group === group.id,
                )}
                getOptionLabel={(option) => option.display_name}
                onChange={(e, value, action) =>
                  handleCheckbox({
                    value,
                    items: groupItems,
                    action,
                  })
                }
                getOptionSelected={(option, id) => option.id === id}
                renderOption={(option) => (
                  <>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={option.selected}
                    />
                    {option.display_name}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // {...autoCompleteProps}
                    variant="outlined"
                    label={group.display_name}
                    placeholder={group.display_name}
                  />
                )}
                disabled={disable}
              />
            </div>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default ActivityFilters;

export function convertAssistancToNumber(assistant) {
  if(assistant === 'WITHOUT' || !assistant) return 0;
  if(assistant === 'WITH') return 1;
}

export function convertNumberToAssistanc(number) {
  if(number === 0 || !number) return 'WITHOUT';
  if(number === 1) return 'WITH';
}
