import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { cloneDeep, findKey, omit } from 'lodash';
import React from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import ConfirmDialog from '../../../components/ConfirmDialog';
import rtApi from '../../../services/rtApi';
import { uploadFile } from '../../../services/uploadFile';
import { CONSTS } from '../../../store/activites/consts';
import { COVER_KEYS } from '../../../utils/consts';
import { propToType, typeToProp } from '../../../utils/mediaUtils';
import ActivityButtons from './ActivityButtons';
import ActivityCovers from './ActivityCovers';
import ActivityDetails from './ActivityDetails';
import ActivityEngine from './ActivityEngine';
import ActivityFilters from './ActivityFilters';
import ActivityMedia from './ActivityMedia';
import ActivitySettings from './ActivitySettings';
import {
  ACTIVITY_CONTROLLER_STATES,
  PAGE_ROLES,
  UPLOAD_CONTROLLER_STATES,
} from './utils';

const mandatoryKeys = ['description', 'display_name'];

class Activity extends React.Component {
  state = {
    pageRole: this.props.initialActivity ? PAGE_ROLES.EXISTING_ACTIVITY : PAGE_ROLES.CREATE_ACTIVITY,
    activity: this.props.initialActivity
      ? cloneDeep(this.props.initialActivity)
      : cloneDeep(CONSTS.ACTIVITY_OBJ),
    coversToUpload: [],
    mediaToUpload: [],
    uploadController: {},
    activityController: ACTIVITY_CONTROLLER_STATES.PENDING_SUBMIT,
    uploadTasks: {},
  };
  constructor(props) {
    super(props);
    /* Relevant only for update */
    this.publishRef = React.createRef();
    this.unpublishRef = React.createRef();
    this.saveRef = React.createRef();
    /* ------------------------ */
    this.props.history.listen(() => {
      localStorage.removeItem('activityPage_state');
    });
  }
  /* -------------------------------- LifeCycle ------------------------------- */
  componentDidMount() {
    this.props.setShowOriginalActivity(false);
    this.initPageRoleState();
    // this.initPersistentState();
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      activity,
      activityController,
      uploadController,
      mediaToUpload,
      coversToUpload,
    } = this.state;
    const {
      activity: prevActivity,
      activityController: prevActivityController,
      uploadController: prevUploadController,
      mediaToUpload: prevMediaToUpload,
      coversToUpload: prevCoversToUpload,
    } = prevState;

    if (prevActivityController !== activityController)
      this.handleActivityController();
    if (prevUploadController !== uploadController)
      this.handleUploadController();

    if (this.props.activity !== prevProps.activity) {
      this.setState((state) =>
        this.props.showOriginalActivity
          ? {
              ...state,
              draft: state.activity,
              activity: cloneDeep(this.props.activity),
            }
          : { ...state, draft: null, activity: state.draft },
      );
    }

    const shouldPersistState =
      prevActivity !== activity ||
      prevMediaToUpload !== mediaToUpload ||
      prevCoversToUpload !== coversToUpload;

    if (shouldPersistState) {
      localStorage.setItem(
        'activityPage_state',
        JSON.stringify(
          omit(this.state, [
            'uploadController',
            'activityController',
            'uploadTasks',
          ]),
        ),
      );
    }
  }

  /* ---------------------------- StateInitializers ---------------------------- */
  initPersistentState() {
    const persistentState = JSON.parse(
      localStorage.getItem('activityPage_state'),
    );
    if (persistentState && !this.props.activity)
      this.setState({ ...persistentState });
  }

  initPageRoleState() {
    if (this.props.match.params.id) {
      this.setState({ pageRole: PAGE_ROLES.EXISTING_ACTIVITY });
    } else {
      this.setState({
        pageRole: PAGE_ROLES.CREATE_ACTIVITY,
      });
    }
  }
  /* -------------------------------------------------------------------------- */
  /*                               State Selectors                              */
  /* -------------------------------------------------------------------------- */
  /* ----------------------------------- id ----------------------------------- */
  /**
   * @type{string}
   */
  get activityId() {
    return this.state.activity.properties.id;
  }
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- properties ------------------------------- */
  getActivityProperties() {
    return this.state.activity.properties;
  }
  /* -------------------------------------------------------------------------- */
  /* --------------------------------- covers --------------------------------- */
  /**
   *
   * @param {COVER_KEYS} cover_type
   * @returns {string} the url of the cover image
   */
  getCoverURL(cover_type) {
    return this.state.activity.properties[cover_type];
  }
  /**
   * @param {COVER_KEYS} cover_type
   * @returns {{type: COVER_TYPES; url: string; local_id: string}}
   */
  getCoverAsObject(cover_type) {
    return {
      type: propToType(cover_type),
      url: this.getActivityProperties()[cover_type],
      local_id: uuid(),
    };
  }
  get activityCovers() {
    let covers = {};
    COVER_KEYS.forEach(
      (key) => (covers[key] = this.getActivityProperties()[key]),
    );
    return covers;
  }
  getAllCoversAsArray = () => {
    if (this.props.showOriginalActivity) {
      return COVER_KEYS.map((key) => this.getCoverAsObject(key));
    }
    const allCovers = COVER_KEYS.map((key) => {
      const isOnToUpload = this.state.coversToUpload.some(
        (item) => typeToProp(item.type) === key,
      );
      if (isOnToUpload) {
        return this.state.coversToUpload.find(
          (item) => typeToProp(item.type) === key,
        );
      }
      return this.getCoverAsObject(key);
    });
    return allCovers;
  };
  /* -------------------------------------------------------------------------- */
  /* ---------------------------------- media --------------------------------- */
  /**@type {MediaItem[]} */
  get media() {
    return this.state.activity.properties.media;
  }
  /**
   *
   * @param {string} _url
   * @returns {MediaItem}
   */
  getUploadedMedia(_url) {
    return this.state.activity.properties.media.find(
      (item) => item.url === _url,
    );
  }
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                               Props Selectors                              */
  /* -------------------------------------------------------------------------- */
  get historyProp() {
    return this.props.history;
  }
  get settingsProp() {
    return this.props.settings;
  }

  //* ------------------------------------------------------------------------ *//
  //*                                Controllers                               *//
  //* ------------------------------------------------------------------------ *//
  handleActivityController() {
    const { activityController, pageRole } = this.state;
    const {
      PENDING_SUBMIT,
      INITIALIZING,
      SAVING_ACTIVITY,
      ACTIVITY_SAVED,
      SAVE_ERROR,
      UPLOADING_FILES,
      UPLOADING_COVER_IMAGES,
      COVER_IMAGES_UPLOADED,
      UPLOADING_MEDIA_FILES,
      MEDIA_FILES_UPLOADED,
      ALL_FILES_UPLOADED,
      UPDATING_ACTIVITY,
      UPDATE_ERROR,
      ACTIVITY_UPDATED,
      PUBLISHING_ACTIVITY,
      PUBLISH_ERROR,
      ACTIVITY_PUBLISHED,
      UNPUBLISHING_ACTIVITY,
      UNPUBLISH_ERROR,
      ACTIVITY_UNPUBLISHED,
    } = ACTIVITY_CONTROLLER_STATES;

    switch (activityController) {
      case PENDING_SUBMIT:
        break;
      case INITIALIZING:
        if (pageRole === PAGE_ROLES.CREATE_ACTIVITY) {
          this.saveActivity();
        } else this.setState({ activityController: ACTIVITY_SAVED });
        break;
      case SAVING_ACTIVITY:
        break;
      case SAVE_ERROR:
        this.onSaveError();
        break;
      case ACTIVITY_SAVED:
        this.uploadFiles();
        break;
      case UPLOADING_FILES:
        break;
      case UPLOADING_COVER_IMAGES:
        break;
      case COVER_IMAGES_UPLOADED:
        this.uploadFiles();
        break;
      case UPLOADING_MEDIA_FILES:
        break;
      case MEDIA_FILES_UPLOADED:
        this.uploadFiles();
        break;
      case ALL_FILES_UPLOADED:
        this.updateActivity();
        break;
      case UPDATING_ACTIVITY:
        break;
      case UPDATE_ERROR:
        this.onUpdateError();
        // let the user try again, when the only action needed is -> updateActivity()
        break;
      case ACTIVITY_UPDATED:
        this.onActivityUpdated();
        break;
      case PUBLISHING_ACTIVITY:
        break;
      case PUBLISH_ERROR:
        this.onPublishError();
        break;
      case ACTIVITY_PUBLISHED:
        this.onPublished();
        break;
      case UNPUBLISHING_ACTIVITY:
        break;
      case UNPUBLISH_ERROR:
        this.onUnpublishError();
        break;
      case ACTIVITY_UNPUBLISHED:
        this.onUnpublished();
        break;
      default:
        break;
    }
  }
  /**
   *
   * @param {MediaItem[]} _filesToUpload
   * @param {Function<Promise>} _uploadFunction
   * @returns {void}
   */
  handleUploadController() {
    const {
      activityController,
      uploadController,
      mediaToUpload,
      coversToUpload,
    } = this.state;
    const {
      PENDING,
      INITIALIZING,
      UPLOADING_FILE,
      UPLOAD_ERROR,
      FILE_UPLOADED,
    } = UPLOAD_CONTROLLER_STATES;
    const {
      UPLOADING_COVER_IMAGES,
      COVER_IMAGES_UPLOADED,
      MEDIA_FILES_UPLOADED,
    } = ACTIVITY_CONTROLLER_STATES;

    let _filesToUpload, _uploadFunction, _resolveState;
    if (activityController === UPLOADING_COVER_IMAGES) {
      _filesToUpload = [...coversToUpload];
      _uploadFunction = this.uploadCover;
      _resolveState = COVER_IMAGES_UPLOADED;
    } else {
      _filesToUpload = [...mediaToUpload];
      _uploadFunction = this.uploadMedia;
      _resolveState = MEDIA_FILES_UPLOADED;
    }
    switch (uploadController) {
      case PENDING:
        break;
      case INITIALIZING:
        // if length of filesToUpload less than 1 setActivityController(_resolveState);
        if (!_filesToUpload[0] || _filesToUpload[0].uploadError)
          this.setActivityController(_resolveState);
        else _uploadFunction(_filesToUpload[0]);
        break;
      case UPLOADING_FILE:
        break;
      case UPLOAD_ERROR:
        // indicate to user and let him try again later
        toast.warning(
          `There was a problem with the upload of
           one or more of your files. You will be able to try again later`,
        );
        let _itemWithError = _filesToUpload[0];
        _filesToUpload.shift();
        this.setState({
          [_filesToUpload]: [..._filesToUpload, _itemWithError],
          uploadController: INITIALIZING,
        });
        break;
      case FILE_UPLOADED:
        if (_filesToUpload.length < 1) {
          this.setState({
            activityController: _resolveState,
            uploadController: PENDING,
          });
        } else this.setUploadController(INITIALIZING);
        break;
      default:
        return;
    }
  }
  //* ------------------------------------------------------------------------- */
  //*                               StateSetters                                */
  //* ------------------------------------------------------------------------- */
  /* --------------------------- activityController --------------------------- */
  /**
   *  Use this to quickly set the activityController State if you dont need to set anything else
   * @param {ACTIVITY_CONTROLLER_STATES} _controllerState
   */
  setActivityController(_controllerState) {
    this.setState({ activityController: _controllerState });
  }
  /* -------------------------------------------------------------------------- */
  /* ---------------------------- uploadController ---------------------------- */
  /**
   *
   * @param {UPLOAD_CONTROLLER_STATES} _controllerState
   */
  setUploadController(_controllerState) {
    this.setState({ uploadController: _controllerState });
  }

  /* -------------------------------------------------------------------------- */
  /* ----------------------------- activitySetters ---------------------------- */
  editActivity(activity) {
    const { showOriginalActivity } = this.props;
    if (showOriginalActivity) {
      toast.warning('Go back to edit mode to edit the activity.');
    }
    let activityToMerge = {};
    if (this.state.pageRole === PAGE_ROLES.EXISTING_ACTIVITY) {
      activityToMerge = activity;
    } else {
      activityToMerge = activity;
    }
    this.setState({
      activity: {
        ...this.state.activity,
        ...activityToMerge,
      },
    });
  }
  resetActivity() {
    if (this.state.pageRole === PAGE_ROLES.CREATE_ACTIVITY)
      this.setState({
        activity: cloneDeep(CONSTS.ACTIVITY_OBJ),
        coversToUpload: [],
        mediaToUpload: [],
      });
    else
      this.setState({
        activity: cloneDeep(this.props.initialActivity),
        coversToUpload: [],
        mediaToUpload: [],
      });
  }

  /* -------------------------------------------------------------------------- */
  /* -------------------------- activity api requests ------------------------- */
  async saveActivity() {
    const { SAVING_ACTIVITY, ACTIVITY_SAVED, SAVE_ERROR } =
      ACTIVITY_CONTROLLER_STATES;
    if (
      this.activityId &&
      this.activityId !== null &&
      this.activityId.length > 0
    ) {
      this.setActivityController(ACTIVITY_SAVED);
      return;
    }
    this.setActivityController(SAVING_ACTIVITY);
    const { activity } = this.state;

    const createActivityCallback = ({ payload }) => {
      this.setState({
        activity: cloneDeep(payload),
        activityController: ACTIVITY_SAVED,
      });
    };

    return rtApi
      .createActivity(activity)
      .then(createActivityCallback)
      .catch((error) => {
        console.log(error);
        return rtApi
          .createActivity(activity)
          .then(createActivityCallback)
          .catch((saveError) => {
            console.log(saveError);
            this.setState({ activityController: SAVE_ERROR, saveError });
          });
      });
  }
  async updateActivity() {
    const { activity } = this.state;
    const { ACTIVITY_UPDATED, UPDATING_ACTIVITY } = ACTIVITY_CONTROLLER_STATES;
    this.setActivityController(UPDATING_ACTIVITY);
    const updateCallback = () => {
      this.setActivityController(ACTIVITY_UPDATED);
    };
    return rtApi
      .updateActivity(activity)
      .then(updateCallback)
      .catch(async (error) => {
        console.log(error);
        return rtApi
          .updateActivity(activity)
          .then(updateCallback)
          .catch((error) => {
            this.setState({
              activityController: ACTIVITY_CONTROLLER_STATES.SAVE_ERROR,
              saveError: error,
            });
          });
      });
  }

  async publishActivity() {
    const { PUBLISHING_ACTIVITY, PUBLISH_ERROR, ACTIVITY_PUBLISHED } =
      ACTIVITY_CONTROLLER_STATES;
    this.setActivityController(PUBLISHING_ACTIVITY);
    return rtApi
      .publishActivity(this.activityId)
      .then(({ payload }) => {
        this.setState({
          activity: { ...payload },
          activityController: ACTIVITY_PUBLISHED,
        });
      })
      .catch((publishError) =>
        this.setState({ publishError, activityController: PUBLISH_ERROR }),
      );
  }

  async unpublishActivity() {
    const { UNPUBLISHING_ACTIVITY, UNPUBLISH_ERROR, ACTIVITY_UNPUBLISHED } =
      ACTIVITY_CONTROLLER_STATES;
    this.setActivityController(UNPUBLISHING_ACTIVITY);
    return rtApi
      .unpublishActivity(this.activityId)
      .then(({ payload }) => {
        this.setState({
          activity: cloneDeep(payload),
          activityController: ACTIVITY_UNPUBLISHED,
        });
      })
      .catch((publishError) =>
        this.setState({ publishError, activityController: UNPUBLISH_ERROR }),
      );
  }
  /* -------------------------------------------------------------------------- */
  /* ------------------------------ change engine ----------------------------- */
  changeEngine(engine) {
    const { activity } = this.state;
    this.editActivity({
      ...activity,
      engine,
    });
    // this.setState({ selectedSetting: null });
  }
  /* -------------------------------------------------------------------------- */
  /* ---------------------------- media and covers ---------------------------- */
  addCover(file) {
    this.setState({
      coversToUpload: [...this.state.coversToUpload, { ...file }],
    });
  }
  deleteCover(type) {
    const isOnToUpload = this.state.coversToUpload.some(
      (item) => item.type === type,
    );
    if (!isOnToUpload) {
      const data = { ...this.state.activity };
      data.properties[typeToProp(type)] = '';
      this.editActivity(data);
    } else {
      const coversToUpload = this.state.coversToUpload.filter(
        (cover) => cover.type !== type,
      );
      this.setState({ coversToUpload });
    }
  }
  addMedia(file) {
    this.setState({
      mediaToUpload: [...this.state.mediaToUpload, { ...file }],
    });
  }

  deleteMedia(file) {
    const { url, local_id } = file;
    const { activity, mediaToUpload } = this.state;
    if (!mediaToUpload.find((item) => item.url === url)) {
      const data = { ...activity };
      const properties = { ...this.getActivityProperties() };
      const filteredMedia = this.media.filter((item) => {
        return item.url !== url;
      });
      this.editActivity({
        ...data,
        properties: { ...properties, media: filteredMedia },
      });
    } else {
      const filteredMediaToUpload = mediaToUpload.filter(
        (item) => item.local_id !== local_id,
      );
      this.setState({ mediaToUpload: filteredMediaToUpload });
    }
  }
  /* -------------------------------------------------------------------------- */
  //*------------------------- upload media and covers ------------------------ */
  uploadFiles() {
    const {
      UPLOADING_FILES,
      UPLOADING_COVER_IMAGES,
      UPLOADING_MEDIA_FILES,
      ALL_FILES_UPLOADED,
    } = ACTIVITY_CONTROLLER_STATES;
    const { INITIALIZING } = UPLOAD_CONTROLLER_STATES;
    const { coversToUpload, mediaToUpload } = this.state;
    this.setActivityController(UPLOADING_FILES);
    if (coversToUpload.length < 1 && mediaToUpload.length < 1) {
      this.setActivityController(ALL_FILES_UPLOADED);
    } else {
      if (coversToUpload.length > 0) {
        this.setActivityController(UPLOADING_COVER_IMAGES);
      } else if (mediaToUpload.length > 0) {
        this.setActivityController(UPLOADING_MEDIA_FILES);
      }
      this.setUploadController(INITIALIZING);
    }
  }

  uploadMedia = async (_mediaItem) => {
    const { activity, uploadTasks, mediaToUpload } = this.state;
    const { UPLOADING_FILE, UPLOAD_ERROR, FILE_UPLOADED } =
      UPLOAD_CONTROLLER_STATES;

    this.setUploadController(UPLOADING_FILE);

    return new Promise((resolve, reject) => {
      uploadFile(_mediaItem, this.activityId, {
        tasksState: uploadTasks,
        setTasksState: (task) =>
          this.setState({
            uploadTasks: { ...uploadTasks, [_mediaItem.fileName]: task },
          }),
      })
        .then((newUrl) => {
          const newUploadMediaArray = [...mediaToUpload];
          newUploadMediaArray.shift();

          const activityMedia = [...this.media];
          activityMedia.push({ type: _mediaItem.type, url: newUrl });

          this.setState({
            activity: {
              ...activity,
              properties: {
                ...this.getActivityProperties(),
                media: activityMedia,
              },
            },
            mediaToUpload: newUploadMediaArray,
            uploadController: FILE_UPLOADED,
          });
          resolve();
        })
        .catch((e) => {
          const mediaWithError = mediaToUpload.map((item) => {
            if (item.local_id === _mediaItem.local_id)
              return { ...item, uploadError: e };
            return item;
          });
          this.setState({
            mediaToUpload: mediaWithError,
            uploadController: UPLOAD_ERROR,
          });
        });
    });
  };

  uploadCover = async (_cover) => {
    const { activity, uploadTasks, coversToUpload } = this.state;
    const { UPLOADING_FILE, UPLOAD_ERROR, FILE_UPLOADED } =
      UPLOAD_CONTROLLER_STATES;

    this.setUploadController(UPLOADING_FILE);
    return new Promise((resolve, reject) =>
      uploadFile(_cover, this.activityId, {
        tasksState: uploadTasks,
        setTasksState: (task) =>
          this.setState({
            uploadTasks: { ...uploadTasks, [_cover.local_id]: task },
          }),
      })
        .then((newUrl) => {
          let covers = cloneDeep(coversToUpload);
          covers.shift();
          this.setState({
            activity: {
              ...activity,
              properties: {
                ...this.getActivityProperties(),
                [typeToProp(_cover.type)]: newUrl,
              },
            },
            coversToUpload: covers,
            uploadController: FILE_UPLOADED,
          });
          resolve();
        })
        .catch((e) => {
          const coversWithError = coversToUpload.map((cover) => {
            if (cover.local_id === _cover.local_id)
              return { ...cover, uploadError: e };
            return cover;
          });
          this.setState({
            coversToUpload: coversWithError,
            uploadController: UPLOAD_ERROR,
          });
        }),
    );
  };
  /* -------------------------------------------------------------------------- */
  /* --------------------------- check "form" fields -------------------------- */
  checkFields = () => {
    const { activity } = this.state;
    const keysValid = mandatoryKeys.every((val) => {
      if (!activity.properties[val]) return false;
      return true;
    });

    return keysValid;
  };
  /* -------------------------------------------------------------------------- */
  //* ---------------------------- Event Handlers ---------------------------- *//
  onUpdateError = () => {
    toast.warning(
      'There was an unexpected error. Please try again. If this problem repeats pleas contact support.',
    );
  };
  onActivityUpdated = () => {
    if (this.state.pageRole === PAGE_ROLES.CREATE_ACTIVITY)
      successToast('Activity created successfully');
    else successToast('Activity updated successfully');
    this.historyProp.push('/');
  };
  onSaveError = () => {
    genericErrorToast();
    this.setActivityController(ACTIVITY_CONTROLLER_STATES.PENDING_SUBMIT);
  };
  onPublishError = () => {
    genericErrorToast();
    this.setActivityController(ACTIVITY_CONTROLLER_STATES.PENDING_SUBMIT);
  };
  onPublished = () => {
    successToast('Activity published successfully');
    this.setActivityController(ACTIVITY_CONTROLLER_STATES.PENDING_SUBMIT);
  };
  onUnpublishError = () => {
    genericErrorToast();
    this.setActivityController(ACTIVITY_CONTROLLER_STATES.PENDING_SUBMIT);
  };
  onUnpublished = () => {
    successToast('Activity unpublished successfully');
    this.setActivityController(ACTIVITY_CONTROLLER_STATES.PENDING_SUBMIT);
  };
  /* -------------------------------------------------------------------------- */
  //*--------------------------------- Renders -------------------------------- */
  ActivityDialogs = () => {
    const { pageRole } = this.state;
    return (
      <>
        {pageRole === PAGE_ROLES.EXISTING_ACTIVITY && (
          <>
            <ConfirmDialog
              ref={this.publishRef}
              confirmed={() => this.publishActivity()}
              title="Are you sure you want to publish?"
            />

            <ConfirmDialog
              ref={this.unpublishRef}
              confirmed={() => this.unpublishActivity()}
              title="Are you sure you want to unpublish?"
            />
            <ConfirmDialog
              ref={this.saveRef}
              confirmed={() =>
                this.setActivityController(
                  ACTIVITY_CONTROLLER_STATES.INITIALIZING,
                )
              }
              title="Are you sure you want to update?"
            />
          </>
        )}
      </>
    );
  };

  render() {
    const {
      title = '',
      settings,
      filters = { groups: [] },
      setShowOriginalActivity,
      showOriginalActivity,
      originalActivity = {},
    } = this.props;
    const { activity, pageRole, activityController, mediaToUpload } =
      this.state;
    const { PENDING_SUBMIT, INITIALIZING } = ACTIVITY_CONTROLLER_STATES;
    return (
      <>
        <Dialog open={activityController !== PENDING_SUBMIT}>
          <DialogTitle>Please wait...</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This should take just a few seconds...
            </DialogContentText>
          </DialogContent>
        </Dialog>

        <div className="Activity">
          <div className="activity-title">{title}</div>
          <ActivityDetails
            activity={activity}
            editActivity={(data) => this.editActivity(data)}
            disable={showOriginalActivity}
          />
          <ActivityEngine
            activity={activity}
            settings={settings}
            pageRole={pageRole}
            changeEngine={(engine) => this.changeEngine(engine)}
            disable={showOriginalActivity}
          />
          <ActivityCovers
            activity={activity}
            addCover={(file) => this.addCover(file)}
            deleteCover={(type) => this.deleteCover(type)}
            media={this.getAllCoversAsArray()}
            disable={showOriginalActivity}
          />
          <ActivityMedia
            key={activity?.properties?.id}
            editActivity={(data) => this.editActivity(data)}
            media={[...this.media, ...mediaToUpload]}
            addMedia={(file) => this.addMedia(file)}
            deleteMedia={(file) => this.deleteMedia(file)}
            disable={showOriginalActivity}
          />
          <ActivitySettings
            activity={activity}
            settings={settings}
            editActivity={(data) => this.editActivity(data)}
            orderedSettings={getOrderedSettings({ activity, settings })}
            disable={showOriginalActivity}
          />
          <ActivityFilters
            activity={showOriginalActivity ? originalActivity : activity}
            filters={filters}
            editActivity={(data) => this.editActivity(data)}
            disable={showOriginalActivity}
          />

          <ActivityButtons
            pageRole={pageRole}
            checkFields={!this.checkFields()}
            showOriginalActivity={showOriginalActivity}
            setShowOriginal={(_show) => setShowOriginalActivity(_show)}
            originalActivity={originalActivity}
            currentVisibility={activity.properties.visibility}
            onSaveActivityClick={() => this.setActivityController(INITIALIZING)}
            onUpdateActivityClick={() => this.saveRef.current.openDialog()}
            onPublishClick={() =>
              this.publishRef.current.openDialog.bind(this)()
            }
            onUnpublishClick={() => this.unpublishRef.current.openDialog()}
            resetActivity={() => this.resetActivity()}
          />
          <this.ActivityDialogs />
        </div>
      </>
    );
  }
}

export default Activity;

//* --------------------------- Helper Functions --------------------------- *//
function getOrderedSettings({ activity, settings }) {
  if (!settings.engines) {
    return [];
  }
  let data = settings.engines[activity.engine].items;
  /*
    sequnce engine definition includes “strikeOutType” setting for use in “levels” engine variation (used for level tests). 
    when creating a sequence activity (without variation) this setting should not be treated. (edited) 
  */
  if (activity.engine === 'sequence') {
    data = data.filter(d => d !== 'strikeOutType')
  }
  const ordered = data.map((val) => {
    const item = findKey(settings.settings, val);
    return {
      engineItem: val,
      itemSettings: settings.settings[item][val],
      itemKey: item,
    };
  });
  return ordered;
}
function genericErrorToast() {
  toast.warning(
    'Something went wrong... Please try again in a moment. If this problem repeat please contact support.',
  );
}
function successToast(message) {
  toast.success(message, {
    position: 'bottom-left',
  });
}
