import { Button, Tooltip } from '@material-ui/core';
import React, { Component } from 'react';
import history from '../../../history';
import { VISIBILITY } from '../../../utils/consts';
import { PAGE_ROLES } from './utils';

class ActivityButtons extends Component {
  renderSaveOrUpdate() {
    const {
      pageRole,
      checkFields,
      onUpdateActivityClick,
      onSaveActivityClick,
      showOriginalActivity,
    } = this.props;
    return (
      <>
        {pageRole === PAGE_ROLES.CREATE_ACTIVITY && (
          <Button
            variant="contained"
            disabled={checkFields}
            color="primary"
            onClick={onSaveActivityClick}
          >
            Save activity
          </Button>
        )}
        {pageRole === PAGE_ROLES.EXISTING_ACTIVITY && (
          <Tooltip
            placement="top"
            title={
              showOriginalActivity
                ? 'Can not update activity in original mode, please back to edit mode.'
                : ''
            }
          >
            <span>
              <Button
                disabled={showOriginalActivity}
                variant="contained"
                color="primary"
                onClick={() => onUpdateActivityClick()}
              >
                Update activity
              </Button>
            </span>
          </Tooltip>
        )}
      </>
    );
  }

  render() {
    const {
      showOriginalActivity,
      resetActivity,
      originalActivity,
      pageRole,
      setShowOriginal,
      currentVisibility,
      onPublishClick,
      onUnpublishClick,
    } = this.props;
    return (
      <div className="actions-buttons">
        <Button variant="contained" onClick={() => history.push('/')}>
          Cancel
        </Button>
        <Tooltip
          placement="top"
          title={
            showOriginalActivity
              ? 'Can not reset activity in original mode, please back to edit mode.'
              : ''
          }
        >
          <span>
            <Button
              disabled={showOriginalActivity}
              variant="contained"
              color="secondary"
              onClick={resetActivity}
            >
              Reset
            </Button>
          </span>
        </Tooltip>
        {this.renderSaveOrUpdate()}
        {currentVisibility === VISIBILITY.HIDDEN && (
          <Button
            disabled={showOriginalActivity}
            variant="contained"
            className="green-button"
            color="primary"
            onClick={onPublishClick}
          >
            Publish new activity
          </Button>
        )}
        {currentVisibility === VISIBILITY.PUBLIC && (
          <Button
            disabled={showOriginalActivity}
            variant="contained"
            className="green-button"
            color="primary"
            onClick={onUnpublishClick}
          >
            Unpublish activity
          </Button>
        )}
        {originalActivity.draft && currentVisibility === VISIBILITY.DRAFT && (
          <Tooltip
            placement="top"
            title={
              showOriginalActivity
                ? 'Can not publish activity in original mode, please back to edit mode.'
                : ''
            }
          >
            <span>
              <Button
                disabled={showOriginalActivity}
                variant="contained"
                className="green-button"
                color="primary"
                onClick={onPublishClick}
              >
                Publish draft
              </Button>
            </span>
          </Tooltip>
        )}
        {pageRole === PAGE_ROLES.EXISTING_ACTIVITY && originalActivity.draft && (
          <Button
            variant="contained"
            className="green-button"
            style={{ width: '200px' }}
            color="primary"
            onClick={() => setShowOriginal(!showOriginalActivity)}
          >
            {showOriginalActivity ? 'Back to edit mode' : 'show original'}
          </Button>
        )}
      </div>
    );
  }
}

export default ActivityButtons;
