export const GET_ALL_ACTIVITIES_SUCCESSFUL =
  'activities.GET_ALL_ACTIVITIES_SUCCESSFUL';
export const GET_ALL_ACTIVITIES_ERROR = 'activities.GET_ALL_ACTIVITIES_ERROR';

export const GET_ACTIVITY_SUCCESSFUL = 'activities.GET_ACTIVITY_SUCCESSFUL';
export const GET_ACTIVITY_ERROR = 'activities.GET_ACTIVITY_ERROR';

export const GET_ACTIVITY_SETTINGS_SUCCESSFUL =
  'activities.GET_ACTIVITY_SETTINGS_SUCCESSFUL';
export const GET_ACTIVITY_SETTINGS_ERROR =
  'activities.GET_ACTIVITY_SETTINGS_ERROR';

export const EDIT_ACTIVITY_SUCCESSFUL = 'activities.EDIT_ACTIVITY_SUCCESSFUL';
export const EDIT_ACTIVITY_ERROR = 'activities.EDIT_ACTIVITY_ERROR';

export const GET_ACTIVITY_FILTERS_SUCCESSFUL =
  'activities.GET_ACTIVITY_FILTERS_SUCCESSFUL';
export const GET_ACTIVITY_FILTERS_ERROR =
  'activities.GET_ACTIVITY_FILTERS_ERROR';

export const RESET_ACTIVITY_SUCCESSFUL =
  'activities.RESERT_ACTIVITY_SUCCESSFUL';
export const RESET_ACTIVITY_ERROR = 'activities.RESERT_ACTIVITY_ERROR';

export const NEW_ACTIVITY_SUCCESSFUL = 'activities.NEW_ACTIVITY_SUCCESSFUL';

export const PUBLISH_ACTIVITY_SUCCESSFUL =
  'activities.PUBLISH_ACTIVITY_SUCCESSFUL';

export const UPDATE_ACTIVITY_SUCCESSFUL =
  'activities.UPDATE_ACTIVITY_SUCCESSFUL';

export const SELECT_ACTIVITY = 'activities.SELECT_ACTIVITY';

export const SELECT_ALL_ACTIVITIES = 'activities.SELECT_ALL_ACTIVITIES';

export const NEW_ACTIVITY_CHANGED = 'activities.NEW_ACTIVITY_CHANGED';

export const NEW_ACTIVITY_CREATED_SUCCESSFUL =
  'activities.NEW_ACTIVITY_CREATED_SUCCESSFUL';

export const NEW_ACTIVITY_RESET = 'activities.NEW_ACTIVITY_RESET';

export const SET_ACTIVITY_TO_EDIT = 'activities.SET_ACTIVITY_TO_EDIT';

export const SET_SHOW_ORIGINAL_ACTIVITY =
  'activities.SET_SHOW_ORIGINAL_ACTIVITY';

export const SET_COVER = 'activities.SET_COVER';
export const SET_COVER_NEW = 'activities.SET_COVER_NEW';

export const SET_MEDIA = 'activities.SET_MEDIA';
export const SET_MEDIA_NEW = 'activities.SET_MEDIA_NEW';
