import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ActivitiesStats from './ActivitiesStats';
import './style.scss';
import * as activitiesActions from '../../store/activites/actions';

const mapDispatchToProps = (dispatch) => {
  const newActivity = () => dispatch(activitiesActions.newActivity());
  return { newActivity };
};

const mapStateToProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActivitiesStats),
);
