import React from 'react';
import ButtonAppBar from "../../components/AppBar";


const Layout = (props) => {
  const {googleLogout, children, history} = props

  return (
    <div className="Layout">
      <ButtonAppBar history={history} logout={googleLogout}/>
      {children}
    </div>
  );
};

export default Layout
