import React, { useEffect, useRef, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';
import DropMedia from '../../../components/DropMedia/DropMedia.component';
import { COVER_DIMENSIONS } from '../../../utils/consts';
import FileUtil from '../../../utils/FileUtil';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { v4 as uuid } from 'uuid';
import { spacesToUnderScore } from './utils';

const ActivityCovers = ({
  media = [],
  addCover,
  deleteCover,
  acceptedFileTypes = ['image/png'],
  disable
}) => {
  const [covers, setCovers] = useState([]);
  const [selectedCover, setSelectedCover] = useState(null);
  const deleteRef = useRef();

  useEffect(() => {
    setCovers([...media]);
  }, [media]);

  const addLocalCover = (type, file) => {
    FileUtil.getBase64FromFile(file).then((base64String) => {
      addCover({
        type,
        url: base64String,
        fileName: spacesToUnderScore(file.path),
        local_id: uuid(),
      });
    });
  };
  const clearLocalItem = (type) => {
    setSelectedCover(null);
    deleteCover(type);
  };
  const handleClickDelete = (type) => {
    setSelectedCover(type);
    deleteRef.current.openDialog();
  };

  const CoverDrops = () => {
    return (
      <>
        {covers.map((item) => {
          let splitType = item.type.split('/');
          const coverType = splitType[1];
          const capitalCasedCoverType = coverType
            .split('')
            .map((letter, index) =>
              index === 0 ? letter.toUpperCase() : letter,
            )
            .join('');
          const { x, y } = COVER_DIMENSIONS[coverType];
          return (
            <Grid item xs={3} key={item.local_id}>
              <DropMedia
                title={`Cover Image (${capitalCasedCoverType} ${x}×${y})`}
                filePath={item.url}
                onDrop={(file) => addLocalCover(item.type, file)}
                local_id={item.local_id}
                fileTypes={acceptedFileTypes}
                onClickDelete={() => handleClickDelete(item.type)}
                disabled={disable}
              />
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <Accordion className="activity-card">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <span className="activity-card-title">Activity Covers *</span>
        <span className="activity-card-sub-title">Define cover images</span>
      </AccordionSummary>
      <AccordionDetails className="activity-card">
        <Grid className="inputs-grid" container spacing={3}>
          <CoverDrops />
        </Grid>
      </AccordionDetails>
      <ConfirmDialog
        ref={deleteRef}
        title="Are you sure you want to delete this cover photo?"
        confirmed={() => clearLocalItem(selectedCover)}
      />
    </Accordion>
  );
};

export default ActivityCovers;
