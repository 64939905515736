import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as activitiesActions from '../../../store/activites/actions';
import * as activitiesSelectors from '../../../store/activites/reducer';

// import { localMediaActionCreator as mediaActions } from '../../../store/media/actions';

import Activity from '../Activity/Activity.component';
// import * as mediaSelectors from '../../../store/media/reducer';

const mapDispatchToProps = (dispatch) => {
  const getActivity = (id) => dispatch(activitiesActions.getActivity(id));
  const setShowOriginalActivity = (show) =>
    dispatch(activitiesActions.setShowOriginalActivity(show));
  return {
    getActivity,
    setShowOriginalActivity
    /* ------------------------- local media management ------------------------- */
  };
};

const mapStateToProps = (state) => {
  const settings = activitiesSelectors.getSettings(state);
  const filters = activitiesSelectors.getFilters(state);

  return {
    settings,
    filters,
    title: 'Create new activity',
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Activity),
);
