import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import history from '../../history';

import useStyles from './useStyles';
import './style.scss';

const removeTokenAndRedirect = () => {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('persist:freqdashboard');
  window.location.reload();
};

const ButtonAppBar = () => {
  // const { logout } = props;
  const classes = useStyles();

  return (
    <div className="AppBar">
      <AppBar color="primary" position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            className={classes.title}
            onClick={() => history.push('/')}
          >
            BlazePod CMS
          </Typography>
          <Button
            className={classes.logout}
            color="inherit"
            onClick={removeTokenAndRedirect}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default ButtonAppBar;
