import React, { useCallback, useEffect, useState } from 'react';
// import rtApi from '../../services/rtApi';
import ActivitiesList from '../ActivitiesList';
import ActivitiesStats from '../ActivitiesStats';

const PureHome = ({
  fetchAllActivities,
  allActivities,
  fetchSettings,
  resetNewActivity
  // listLength = allActivities ? allActivities.length : 0,
}) => {
  const [filteredData, setFilteredData] = useState(allActivities);

  const init = useCallback(() => {
    fetchAllActivities();
    fetchSettings();
    setFilteredData();
  }, [fetchAllActivities, fetchSettings]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    setFilteredData(allActivities);
  }, [allActivities]);

  const filterList = (text) => {
    const result = allActivities.filter((data) => {
      return data.properties.display_name.toLowerCase().includes(text);
    });
    setFilteredData(result);
  };
  const filterByVisibility = (filter) => {
    if (filter === '') {
      setFilteredData(allActivities);
    } else {
      const result = allActivities.filter((data) => {
        if (filter === 'DRAFT') return !!data.draft;
        return data.properties.visibility === filter;
      });
      return setFilteredData(result);
    }
  };

  return (
    <>
      {allActivities && (
        <>
          <ActivitiesStats
            searchText={filterList}
            stats={allActivities}
            resetNewActivity={resetNewActivity}
            filterByVisibility={filterByVisibility}
          />
          <ActivitiesList list={filteredData} />
        </>
      )}
    </>
  );
};

export default PureHome;
