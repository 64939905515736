export const VISIBILITY = {
  PUBLIC: 'PUBLIC',
  HIDDEN: 'HIDDEN',
  DRAFT: 'DRAFT',
};

export const MEDIA_TYPES = [
  { value: 'setup', title: 'Setup' },
  { value: 'video', title: 'Video' },
  { value: 'summary', title: 'Summary' },
  { value: 'image', title: 'Image' },
];

export const EXCLUDE_SETTINGS = ['stations', 'cycles', 'delayCycles'];

export const EXCLUDE_FILTERS = ['players', 'pods'];

export const COLORS = {
  BLUE: { name: 'Blue', key: 'BLUE', hex: '#03a9f4' },
  GREEN: { name: 'Green', key: 'GREEN', hex: '#4caf50' },
  RED: { name: 'Red', key: 'RED', hex: '#f44336' },
  YELLOW: { name: 'Yellow', key: 'YELLOW', hex: '#ffeb3b' },
  ORANGE: { name: 'Orange', key: 'ORANGE', hex: '#ff9800' },
  LIGHT_BLUE: { name: 'Light Blue', key: 'LIGHT_BLUE', hex: '#03a9f4' },
  PURPLE: { name: 'Purple', key: 'PURPLE', hex: '#673ab7' },
  PINK: { name: 'Pink', key: 'PINK', hex: '#e91e63' },
  RANDOM: { name: 'Random', key: 'RANDOM', hex: '#000000' },
  PRIMARY: { name: 'Primary', key: 'PRIMARY', hex: '#3f51b5' },
};

export const COVER_DIMENSIONS = {
  rect: { x: 960, y: 585 },
  small: { x: 567, y: 444 },
  box: { x: 690, y: 690 },
};

export const COVER_KEYS = ['cover_box', 'cover_rect', 'cover_small'];
